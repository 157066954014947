//import "./theme/dark/flexmonster.css";
import "./flexmonster.css";
import "./customDay.css"
import { useRef, useState, useEffect } from "react";
import { View, Dimensions, StyleSheet } from 'react-native'
import * as FlexmonsterReact from "react-flexmonster";
import FlexConfig from "./FlexConfig";
import FlexModalSave from "./FlexModals/FlexModalSave";
import FlexModalOpen from "./FlexModals/FlexModalOpen";
import FlexModalDelete from "./FlexModals/FlexModalDelete";

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const FlexComponentDay = (props) => {

    const toolbar = props.toolbar
    const config = props.config
    const pivotRef = useRef(null);
    const pivotData = props.pivotData
    const theme = props.theme
    const currentReport = props.currentReport
    const currentReportName = props.currentReportName
    const setCurrentReportName = props.setCurrentReportName
    const setNewSettings = props.setNewSettings
    const saveReport = props.saveReport
    const deleteReport = props.deleteReport
    const fmKey = props.fmKey
    const [saveOpen, setSaveOpen] = useState(false)
    const [openOpen, setOpenOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const reportList = props.reportList
    const reportNameFallback = props.reportNameFallback
    const [currentSettings, setCurrentSettings] = useState(null)
    const currentReportConfig = props.currentReportConfig
    const currentMetrics = props.currentMetrics

    const primaryMetric = currentMetrics.primaryMetric
    const secondaryMetric = currentMetrics.secondaryMetric
    const primaryRevenue = currentMetrics.primaryRevenue


    function addCaptions(data) {
        const newItem = JSON.parse(JSON.stringify(data)); // Deep copy to avoid mutation
        newItem.slice.measures = newItem.slice.measures.map(measure => {
            let customMetric;
            if (measure.uniqueName === 'ftds') {
                customMetric = primaryMetric
            } else if (measure.uniqueName === 'regs') {
                customMetric = secondaryMetric
            } else if (measure.uniqueName === 'ftd_ngr') {
                customMetric = primaryRevenue
            } else if (measure.uniqueName === 'ftd') {
                customMetric = primaryMetric
            } else if (measure.uniqueName === 'reg') {
                customMetric = secondaryMetric
            } else if (measure.uniqueName === 'ngr') {
                customMetric = primaryRevenue
            } else {
                customMetric = measure.uniqueName
            }

            return {
                ...measure,
                caption: customMetric,
                grandTotalCaption: customMetric
            };
        });
        return newItem;
}
    

    function createColumnArray(length, width) {
        return Array.from({ length }, (v, idx) => ({
            idx: idx,
            width: width  // Dynamically setting width based on input
        }));
    }

    const configUpdate = {
        data: config.data,
        dataset: config.dataset,
        dim1: config.dim1,
        dim2: config.dim2,
        fields: config.fields,
        currentMetrics: currentMetrics
    }

    const reportConfig = FlexConfig(configUpdate)
  
    const reportRows = reportConfig.rows
    const reportColumns = reportConfig.columns
    const reportSlice = reportConfig.slice
    const reportFormats = reportConfig.formats
    //console.log(reportMeasures)

    const reportData = {
        dataSource: { "data": pivotData },
        /*mapping: {
            'ftds' : {
                caption: primaryMetric
            },
            'CPA' : {
                "uniqueName": "CPA",
                "formula": `sum('spend') / sum('${primaryMetric}')`,
                "caption": "cpa",
                "format": "currency"
            }
        },
        options: {grid: {"title" : `Template: ${currentReportName}`}},
        */
        formats: reportFormats,
        slice : currentReport.slice || reportSlice,
        options: {
            showAggregationLabels: false
            }
    }


    function flexUpdate() {
        let measures = pivotRef.current.flexmonster.getMeasures();
        let fields = pivotRef.current.flexmonster.getColumns();
        let columns;

        if (!measures) {
            columns = 5
        } else if (measures.length && fields.length) {
            columns = measures.length + fields.length
        } else { columns = 5 }

        const widthState = (windowWidth * 0.84) / columns
        const columnData = createColumnArray(columns, widthState)

        pivotRef.current.flexmonster.setTableSizes({
            "columns": createColumnArray(columns, widthState)
        })
        const currentReportState = pivotRef.current.flexmonster.getReport()
        const currentReportStateSettings = {
            formats: currentReportState.formats,
            slice: currentReportState.slice,
            options: {
            showAggregationLabels: false
            }
        }
        const addCaptionBase = addCaptions(currentReportStateSettings)

        setCurrentSettings(addCaptionBase)

    }

    function saveHandler() {
        setSaveOpen(true)
        
    }

    function deleteHandler() {
        setDeleteOpen(true)
    }

    function openHandler() {
        setOpenOpen(true)
    }

    function updateReport () {
        const reportDataUpdate = {
            dataSource: { "data": pivotData },
            formats: currentReport.formats,
            slice : currentReport.slice,
            options: {
            showAggregationLabels: false
            }
        }
        const addCaptionBase = addCaptions(reportDataUpdate)

        setCurrentSettings(addCaptionBase)
        pivotRef.current.flexmonster.setReport(addCaptionBase);
    }

    function customizeToolbar(toolbar) {
        // Get all tabs
        let tabs = toolbar.getTabs();
        toolbar.getTabs = function () {
            // Remove the Connect tab using its id
            tabs = tabs.filter(tab => tab.id != "fm-tab-connect");
            tabs = tabs.filter(tab => tab.id != "fm-tab-open");
            tabs = tabs.filter(tab => tab.id != "fm-tab-save");
            tabs.push({
                id: 'fm-tab-customsave',
                title: 'Template',
                icon: this.icons.save,
                menu: [{
                    title: 'Save',
                    id: 'fm-tab-customsave-save',
                    handler: saveHandler,
                    icon: this.icons.save
                },
                {
                    title: 'Open',
                    id: 'fm-tab-customsave-open',
                    handler: openHandler,
                    icon: this.icons.open_local
                },
                {
                    title: 'Delete',
                    id: 'fm-tab-customsave-delete',
                    handler: deleteHandler,
                    icon: this.icons.fullscreen
                }
                ]
            })
            return tabs;
        }
    }



    useEffect(() => {
        // Check if pivotData is not undefined and the Flexmonster component is mounted
        if (pivotData && pivotRef.current) {
            // Update Flexmonster with the new data
            const addCaptionBase = addCaptions(reportData)
            pivotRef.current.flexmonster.setReport(addCaptionBase);
        }
    }, [currentReport]);

    const colorScheme = ["#272834", "#2e324a",
        "#394979", "#3a61ac", "#2a7be3", "#1E1E1F"]

    function getColorFromRange(value, minValue, maxValue) {
        if (isNaN(value)) {
            value = minValue;
        }
        value = Math.max(minValue, Math.min(value, maxValue));
        var perc = (minValue + value) / maxValue;
        var colorIdx = Math.round((colorScheme.length - 1) * perc);
        return colorScheme[colorIdx];
    }

    const customizeCellFunction = (cell, data) => {
        if (data.isTotalRow) {
            cell.style["background-color"] = `${theme.backgroundColor2} !important`
            let textShadowColor = "#095231";
            cell.style["color"] = "white !important";
            cell.style["font-weight"] = "bold !important";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "spend") {
            let columnValues = pivotData.map(function (item) {
                return item["spend"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "impressions") {
            let columnValues = pivotData.map(function (item) {
                return item["impressions"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "clicks") {
            let columnValues = pivotData.map(function (item) {
                return item["clicks"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "regs") {
            let columnValues = pivotData.map(function (item) {
                return item["regs"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "CPR") {
            let columnValues = pivotData.map(function (item) {
                return item["spend"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "ftds") {
            let columnValues = pivotData.map(function (item) {
                return item["ftds"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;

        } else if (data.measure && data.measure.uniqueName === "CPA") {
            let columnValues = pivotData.map(function (item) {
                return item["spend"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;
        } else if (data.measure && data.measure.uniqueName === "ngr") {
            let columnValues = pivotData.map(function (item) {
                return item["spend"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;
        } else if (data.measure && data.measure.uniqueName === "ftd_ngr") {
            let columnValues = pivotData.map(function (item) {
                return item["spend"];
            });
            let minValue = Math.min.apply(null, columnValues);
            let maxValue = Math.max.apply(null, columnValues);
            cell.style["background-color"] = getColorFromRange(data.value, minValue, maxValue);
            let textShadowColor = "#095231";
            cell.style["color"] = "white";
            cell.style["font-weight"] = "bold";
            cell.style["text-shadow"] = `0px 2px 3px ${textShadowColor}`;
        }
    };

    const customReportData = addCaptions(reportData);

    return (
        <View style=
            {styles.container}
        >
            <FlexmonsterReact.Pivot
                toolbar={toolbar}
                report={customReportData}
                ref={pivotRef}
                width='100%'
                height='100%'
                licenseKey={fmKey}
                reportcomplete={flexUpdate}
                //beforegriddraw={setColumns}
                customizeCell={customizeCellFunction}
                beforetoolbarcreated={customizeToolbar}
            />
            <FlexModalSave
                open={saveOpen}
                setOpen={setSaveOpen}
                //modelHandler={modelHandler}
                //models={models}
                reportList={reportList}
                theme={theme}
                //setNewReportName={setNewReportName}
                reportNameFallback={reportNameFallback}
                saveReport={saveReport}
                currentSettings={currentSettings}
                setCurrentReportName={setCurrentReportName}
                currentReportConfig={currentReportConfig}
                currentReportName={currentReportName}
            />
            <FlexModalOpen
                open={openOpen}
                setOpen={setOpenOpen}
                //modelHandler={modelHandler}
                //models={models}
                reportList={reportList}
                theme={theme}
                //setNewReportName={setNewReportName}
                reportNameFallback={reportNameFallback}
                saveReport={saveReport}
                currentSettings={currentSettings}
                setCurrentReportName={setCurrentReportName}
                currentReportConfig={currentReportConfig}
                updateReport={updateReport}
                currentReportName={currentReportName}
            />
             <FlexModalDelete
                open={deleteOpen}
                setOpen={setDeleteOpen}
                //modelHandler={modelHandler}
                //models={models}
                reportList={reportList}
                theme={theme}
                //setNewReportName={setNewReportName}
                reportNameFallback={reportNameFallback}
                deleteReport={deleteReport}
                currentSettings={currentSettings}
                setCurrentReportName={setCurrentReportName}
                currentReportConfig={currentReportConfig}
                updateReport={updateReport}
                currentReportName={currentReportName}
            />
            
        </View>
    )

}



export default FlexComponentDay

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    }
}
)
