import moment from 'moment-timezone';

export default function ParseDaily (cubeResponse, xAxisValue, table, time) {

    var dataset = cubeResponse

    if (!dataset || dataset.length === undefined) {
      return null;
    }
    const xAxis = [xAxisValue]
    const impressions = `impressions`
    const clicks = `clicks`
    const spend = `spend`
    const regs = `regs`
    const ftds = `ftds`

    var agg_result = [];

      dataset.reduce(function (res, value) {

        let axisValue;

        if (xAxisValue === 'date' && time === 'daily') {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM-DD')
        } else if (xAxisValue === "date" && time === 'monthly') {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM')
          }  else if (xAxisValue === "date" && time === "weekly") {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
          } else if (xAxisValue === "date" && time === "yearly") {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM')
          }else { axisValue = value[xAxis] }

        if (!res[axisValue]) {
          res[axisValue] = {
            [xAxisValue]: axisValue,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
          };
          agg_result.push(res[axisValue]);
        }
        res[axisValue].impressions += parseInt(value[impressions]) || 0;
        res[axisValue].clicks += parseInt(value[clicks]) || 0;
        res[axisValue].spend += parseInt(value[spend]) || 0;
        res[axisValue].regs += parseInt(value[regs]) || 0;
        res[axisValue].ftds += parseInt(value[ftds]) || 0;
    
        return res;
      }, {});

      agg_result = agg_result.map((obj) => {
        const impressions = obj.impressions
        const clicks = obj.clicks
        const spend = obj.spend;
        const regs = obj.regs
        const ftds = obj.ftds;
        const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        return {
          ...obj,
          ctr: parseFloat(ctr).toFixed(4),
          cpc: parseFloat(cpc).toFixed(2),
          cpa: parseInt(cpa),
          cpr: parseInt(cpr),
        };
      });

    const keysToKeep = [xAxisValue, 'impressions', 'clicks', 'ctr', 'cpc',, 'spend', 'regs', 'ftds', 'cpa']

      const filteredResult = agg_result.map(item => {
        const filteredItem = {};
      
        keysToKeep.forEach(key => {
          if (item.hasOwnProperty(key)) {
            filteredItem[key] = item[key];
          }
        });
      
        return filteredItem;
      });

     
      if (xAxisValue === 'date' && time === "monthly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      if (xAxisValue === 'date' && time === "weekly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      if (xAxisValue === 'date' && time === "yearly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }
      
      if (xAxisValue === 'date' && time != 'monthly') {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM-DD');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      else {filteredResult.sort((a, b) => a[xAxisValue] - b[xAxisValue]);}

      return filteredResult

}