import React, { useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';

const PieChart = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Rating %',
        data: dataProp,
        backgroundColor: theme.backgroundColor2,
        backgroundColor: [
            theme['gradc10'],
            theme['gradc40'],
            theme['gradc70'],
            theme['gradc100'],
            theme['gradc20'],
            theme['gradc60'],
            theme['gradc90'],
            theme['gradc30'],
            theme['gradc50'],
            theme['gradc20']
          ],
      },
    ],
  };

  const options = {

    plugins: {
      legend: {
        display: true,
        text: 'Chart Title',
        labels: {
          color : theme.color,
          // This more specific font property overrides the global property
          font: {
              size: 12,
              family : 'Electrolize'
          }
      }
      },
      tooltip: {
        enabled: true,
      },
      title: {
        display: false,
        text: 'Chart Title',
        font: {
          size: 24, // Set the font size for the chart title
          weight: 'bold', // Set the font weight for the chart title
  
        },
      },
    },
  };

  return <Doughnut ref={chartRef} data={data} options={options} />;
};

export default PieChart;
