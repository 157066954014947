import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import LoadingComponent from '../Components/Loader.js';
import DashEditorContent from './DashEditorContent.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const DashEditorPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    // Convert the start and end dates to strings in YYYY-MM-DD format
    const startDateString = previousDate.toISOString().slice(0, 10);
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');

    const initialLayout = [
        { i: "item1", x: 0, y: 0, w: 5, h: 5, maxH: 22, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}  },
        { i: "item2", x: 1, y: 0, w: 6, h: 4, minW: 2, maxW: 4, maxH: 22, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}  },
        { i: "item3", x: 4,y: 0, w: 4, h: 4, maxH: 22, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}  }
      ]

      const initialChartLayout = [
        { i: "item1", x: 0, y: 0, w: 5, h: 5, maxH: 22, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
        { i: "item2", x: 1, y: 0, w: 6, h: 4, minW: 2, maxW: 4, maxH: 22, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
        { i: "item3", x: 4, y: 0, w: 4, h: 4, maxH: 22, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp, reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} }
      ];

      const initialConfig = [
        {"gridConfig": "gridConfig1", "gridLayout":initialLayout,  "gridChartLayout": initialChartLayout, "reportName" : 'Dashboard 1', 'size' : 3}
    ]

    const initialDashConfig = JSON.parse(localStorage.getItem("gridConfig"))
    
    if (!initialDashConfig) {
    localStorage.setItem("gridConfig", JSON.stringify([initialConfig]))
    };

    
    const getDashConfigs = JSON.parse(localStorage.getItem("gridConfig")) 
    const [dashConfigs, setDashConfigs] = useState(getDashConfigs)
    const viewerVisible = props.viewerVisible
    const setViewerVisible = props.setViewerVisible
    const [editorVisible, setEditorVisible] = useState(false)
    const homeTitle = "Daily Summary";
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_daily";

    

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        channel: "Total",
        brand: 'Total',
        kw_type: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    const brand = (filters.brand === "Total") ? "null" : filters.brand;
    const channel = (filters.channel === "Total") ? "null" : filters.channel;
    const type = (filters.kw_type === "Total") ? "null" : filters.kw_type;

    const brandOp = (filters.brand === "Total") ? "notEquals" : "equals";
    const channelOp = (filters.channel === "Total") ? "notEquals" : "equals";
    const typeOp = (filters.kw_type === "Total") ? "notEquals" : "equals";

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        brand: "Total",
        channel: "Total",
        kw_type: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            channel: prop.channel,
            brand: prop.brand,
            kw_type: prop.kw_type,
        };

        setFilters(filterUpdate)

    };

    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = brand
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = channel
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = type
        filter3Operator = ['equals']
    }

    

    const cubeDims = ['date', 'brand', 'channel', 'kw_type']
    const dimList = [filter1name, filter2name, filter3name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const filterSql = [dim1Value, dim2Value, dim3Value]

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "impressions"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
        requestDimData(dimQuery, config_url, support_key, support_id, 'POST')
      }, [configArray, filters])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponse = configResponse.data
    const dimCubeResponse = dimResponse.data 

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueBrands = getUniqueValues(dimCubeResponse, filter1name);
    const uniqueChannels = getUniqueValues(dimCubeResponse, filter2name);
    const uniqueTypes = getUniqueValues(dimCubeResponse, filter3name);

    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const agg_result = cubeResponse.map((obj) => {
        const date = moment.tz(obj.date, 'Europe/Paris').format('YYYY-MM-DD');
        const spend = parseFloat(obj.spend)
        const ftds = parseInt(obj.ftds)
        const cpa = parseFloat((spend / ftds).toFixed(2))
        const impressions = parseInt(obj.impressions)
        const clicks = parseInt(obj.clicks)
        const regs = parseInt(obj.regs)
        const cpc = parseFloat((spend / clicks).toFixed(2))
        const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

        return {
            ...obj,
            cpc: cpc,
            cpa: cpa,
            clickConv: ftdConv

        };
    });

    const dateLabels = getUniqueValues(agg_result, 'date')

    function addDashboard () {
        const configLength = dashConfigs.length
        const newDashName = 'gridConfig'+(configLength + 1)
        const reportName = 'Dashboard '+(configLength + 1)
        const layoutName = 'gridLayout'
        const chartLayoutName = 'gridChartLayout'

        const initialLayout = [
            { i: "item1", x: 0, y: 0, w: 2, h: 2, maxH: 18, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item2", x: 1, y: 0, w: 2, h: 2, minW: 2, maxW: 4, maxH: 18, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item3", x: 4, y: 0, w: 2, h: 2, maxH: 18, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} }
          ]

          const initialChartLayout = [
            { i: "item1", x: 0, y: 0, w: 5, h: 5, maxH: 22, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item2", x: 1, y: 0, w: 6, h: 4, minW: 2, maxW: 4, maxH: 22, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item3", x: 4, y: 0, w: 4, h: 4, maxH: 22, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} }
          ];

        const newChild = [
            {"gridConfig": newDashName, gridLayout :initialLayout,  gridChartLayout: initialChartLayout, reportName: reportName }
        ]
     
        
        const configCopy = [...dashConfigs, newChild];       
    
    // Update the state with the new array
        setDashConfigs(configCopy);

        localStorage.setItem("gridConfig", JSON.stringify(configCopy));
        
    }

    function removeDashboard(gridConfigValue) {
        // Check if the length of dashConfigs is 1
        if (dashConfigs.length === 1) {
            return; // Do nothing if there is only one item
        }
    
        // Filter out the item based on the gridConfigValue
        const configCopy = dashConfigs.filter((item, index) => index !== gridConfigValue);
        setDashConfigs(configCopy);
    
        localStorage.setItem("gridConfig", JSON.stringify(configCopy));        
    }

    return (
        <View style={styles.container}>

                <DashEditorContent

                    theme={theme}
                    uniqueBrands={uniqueBrands}
                    uniqueChannels={uniqueChannels}
                    uniqueTypes={uniqueTypes}
                    dateLabels={dateLabels}
                    agg_result={agg_result}
                    cubeTable={cubeTable}
                    dashConfigs={dashConfigs}
                    setDashConfigs={setDashConfigs}
                    viewerVisible={viewerVisible}
                    setViewerVisible={setViewerVisible}
                    editorVisible={editorVisible}
                    setEditorVisible={setEditorVisible}
                    addDashboard={addDashboard}
                    removeDashboard={removeDashboard}
                    filters={filters}
                    setFilters={setFilters}
                    startDateString={startDateString}
                    yesterdayProp={yesterdayProp}
                    currentMetrics={currentMetrics}
                />


        </View>

    )

}

export default DashEditorPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.929,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})