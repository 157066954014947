import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import WebFont from 'webfontloader';
import FlexComponentDay from './FlexComponentDay';
import flexBaseConfig from './flexSettings.json'

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const PivotTableContent = (props) => {

    const themeType = localStorage.getItem('mode') || 'white'
    const panelContent = props.panelContent
    const lastFlexUsed = props.lastFlexUsed
    const currentMetrics = props.currentMetrics
    const fmKey = props.fmKey
    
    const [currentReportName, setCurrentReportName] = useState(lastFlexUsed)
    const currentReportConfig = props.currentReportConfig
    const setCurrentReportConfig = props.setCurrentReportConfig
    
    const reportList = props.reportList
    const [reportListState, setReportListState] = useState(reportList)
    const reportMap = props.reportMap
    const currentReportIndex = reportMap[currentReportName]
    const reportLength = reportList.length
    const reportLenth1  = reportLength + 1
    const reportNameFallback = `default_${reportLenth1}`

    const currentReportFallback = currentReportConfig[currentReportIndex][currentReportName][panelContent]
    const currentReport = currentReportConfig[currentReportIndex][currentReportName][panelContent] || currentReportFallback


    const [newSettings, setNewSettings] = useState(currentReport)
    const pivotConfig = props.pivotConfig
    const pivotData = pivotConfig.data
    const theme = props.theme
    
    const setPanelContent = props.setPanelContent

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    

    function deleteReport (deleteProps) {
        console.log(deleteProps)
        const selectedReportIndex = deleteProps.selectedReportIndex
        const reportConfigCopy = deleteProps.reportConfigCopy
        const textValue = deleteProps.textValue
        let deleteReportMap;

        function extractReports(data) {
            let identifiers = [];
        
            data.forEach(item => {
                Object.keys(item).forEach(key => {
                    identifiers.push(key);
                });
            });
        
            return identifiers;
        }

        if (textValue === currentReportName) {
            localStorage.setItem('lastFlex', 'default')
            localStorage.setItem('flexConfig', JSON.stringify(reportConfigCopy))
            deleteReportMap = extractReports(reportConfigCopy)
            console.log(deleteReportMap)
            setReportListState(deleteReportMap)
            setCurrentReportName('default')
            setCurrentReportConfig(reportConfigCopy)

        } else {
            //localStorage.setItem('lastFlex', 'default')
            localStorage.setItem('flexConfig', JSON.stringify(reportConfigCopy))
            deleteReportMap = extractReports(reportConfigCopy)
            console.log(deleteReportMap)
            setReportListState(deleteReportMap)
            setCurrentReportConfig(reportConfigCopy)
        }
    }

    

    function saveReport (reportArray) {

        const reportName = reportArray.reportName
        const reportSlice = reportArray.currentSettings.slice
        const reportFormats = reportArray.currentSettings.formats
        const reportMapping = reportArray.reportMap
        const currentReportIndex2 = reportMapping[reportName]

        let pushReport;

        if (panelContent === 'summary') {
            pushReport = {
                [reportName]: {
                    summary : {
                        formats: reportFormats,
                        slice: reportSlice,
                    },
                    budget: {
                        formats : flexBaseConfig[0]['default']['budget']['formats'],
                        slice: flexBaseConfig[0]['default']['budget']['slice']
                    },
                    publisher: {
                        formats : flexBaseConfig[0]['default']['publisher']['formats'],
                        slice: flexBaseConfig[0]['default']['publisher']['slice']
                    }
                }
            }
        }
        else if ( panelContent === 'budget') {
            pushReport = {
                [reportName]: {
                    summary : {
                        formats : flexBaseConfig[0]['default']['summary']['formats'],
                        slice: flexBaseConfig[0]['default']['summary']['slice']
                    },
                    budget: {
                        formats : reportFormats,
                        slice: reportSlice
                    },
                    publisher: {
                        formats : flexBaseConfig[0]['default']['publisher']['formats'],
                        slice: flexBaseConfig[0]['default']['publisher']['slice']
                    }
                }
            }
        } else if (panelContent === 'publisher') {
            pushReport = {
                [reportName]: {
                    summary : {
                        formats : flexBaseConfig[0]['default']['summary']['formats'],
                        slice: flexBaseConfig[0]['default']['summary']['slice']
                    },
                    budget: {
                        formats : flexBaseConfig[0]['default']['budget']['formats'],
                        slice: flexBaseConfig[0]['default']['budget']['slice']
                    },
                    publisher: {
                        formats : reportFormats,
                        slice: reportSlice
                    }
                }
            }
    }

        const settings = {

                    "formats" : reportFormats,
                    "slice" : reportSlice
            }

        if (reportListState.includes(reportName)) {
            const existingCopy = currentReportConfig
            
            existingCopy[currentReportIndex2][reportName][panelContent] = settings

                
            setCurrentReportConfig(existingCopy)
            localStorage.setItem('flexConfig', JSON.stringify(existingCopy))
            const newReportList = [...reportList, reportName]
            setReportListState(newReportList)
            setCurrentReportName(reportName)
        } else {
            const copy = currentReportConfig
            const newCopy = [
                ...copy,
                pushReport
            ]
 
            setCurrentReportConfig(newCopy)
            localStorage.setItem('flexConfig', JSON.stringify(newCopy))
            const newReportList = [...reportList, reportName]
            setReportListState(newReportList)
            setCurrentReportName(reportName)
        }

    }

    let responseHeight;

    if (windowWidth < 1441) {
        responseHeight = windowHeight * 0.11
    } else if (windowWidth > 2000) {
        responseHeight = windowHeight * 0.08
    } else { responseHeight = windowHeight * 0.08 }


    const panelChild = {
        flex: 1,
        height: windowHeight * 0.2,
        width: windowWidth,
        borderWidth: 1,
        borderColor: 'grey',

        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        flexDirection: 'row',
    }


    const tablePanel = {
        width: '100%',
        height: '100%',
        borderColor: 'blue',
        borderWidth: 0
    }



        return (
            <View style={styles.container}>
                <View style={panelChild}>
                    <View style={tablePanel}>
                    <FlexComponentDay
                        toolbar={true}
                        config={pivotConfig}
                        pivotData={pivotData}
                        theme={theme}
                        themeType={themeType}
                        currentReport={currentReport}
                        setCurrentReport={setCurrentReportConfig}
                        currentReportName={currentReportName}
                        setCurrentReportName={setCurrentReportName}
                        deleteReport={deleteReport}
                        saveReport={saveReport}
                        //newSettings={newSettings}
                        setNewSettings={setNewSettings}
                        reportList={reportList}
                        reportNameFallback={reportNameFallback}
                        currentReportConfig={currentReportConfig}
                        currentMetrics={currentMetrics}
                        fmKey={fmKey}

                    />
                    </View>
                   
                </View>
 
            </View>

        )

    }

    export default PivotTableContent

    const styles = StyleSheet.create({
        container: {
            borderWidth: 0,
            borderColor: 'blue',
            height: (windowHeight * 0.93),
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'Electrolize'
        }
    })