import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const prodDomain = 'www.pattern-inc.io'
const localDomain = 'dev-hldt17c2gz1xk402.eu.auth0.com'
//dev
//const fmKey = "Z7KJ-10AD5U-2Y5Y43-4V1L1H-6T686U-303W71-5M0B5C-0O4Q3M-4D6B0U-5I1R1U-383R2L-73"
//prod
const fmKey = "Z7Z5-107C2M-4F5E22-2F005M-6Y3C60-180H6E-005Z69-5G256W-2X6W2K-564Y3Q-1X00"

root.render(
  <React.StrictMode>

    <Auth0Provider
      //domain={localDomain}
      domain={prodDomain}
      clientId={process.env.REACT_APP_CUBE_CLIENT_ID}
      authorizationParams={{
        //redirect_uri: 'http://localhost:3000/home'
        redirect_uri: 'https://pattern-inc.io/home'

      }}
    >
      <App fmKey={fmKey} />
    </Auth0Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
