import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import { postToSignedUrl } from './SupportSigner.js';
import signRequest from './SupportSigner.js';
import LoadingComponent from '../Components/Loader.js';
import SupportTicketCube from './SupportTicketCube.js';
import SupportTicketsParse from './SupportTicketsParse.js';
import SupportTicketsPanels from './SupportTicketsPanels.js';

const windowHeight = Dimensions.get('window').height;

const SupportTicketsPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const userNickname = configArray.userNickname
    const clientID = configArray.clientID
    const cubeTable = 'pattern_cases_viz'

    const externalFilter = ['External']
    const internalFilter = ['Internal', 'External']
    let sourceFilter;
    if (userNickname === 'adam' || userNickname === 'joao' || userNickname === 'pedro') {
        sourceFilter = internalFilter
    } else {
        sourceFilter = externalFilter
    }

    const cubeDims = ['date', 'client', 'userNickname', 'user_type', 'ticket_type', 'report_type', 'critical_bool', 'description', 'current_case_status']

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": "2024-10-01",
          "end": "2025-12-31",
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [

          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": [`client = \"${clientID}\"`]
        }
    }


    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };


    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
      }, [configArray])

    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponse = configResponse.data


    const supportData = SupportTicketsParse(cubeResponse)

    return (
        <View style={styles.container}>
            <SupportTicketsPanels
                theme={theme}
                configArray={configArray}
                supportData={supportData}
            />

        </View>
    )

}

export default SupportTicketsPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    }
})