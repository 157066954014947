import moment from 'moment-timezone';

export default function ParseMain (cubeResponse, xAxisValue, currentMetric1, currentMetric2, table, currentType, start, end, time) {

    var dataset = cubeResponse
  
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculate the difference in days
    const differenceInDays = Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000));


    if (!dataset || dataset.length === undefined) {
      return null;
    }
    const xAxis = [xAxisValue]
    const impressions = `impressions`
    const clicks = `clicks`
    const spend = `spend`
    const regs = `regs`
    const ftds = `ftds`
    const ngr = `ngr`

    var agg_result = [];

      dataset.reduce(function (res, value) {

        let axisValue;

        if (xAxisValue === 'date' && time === "monthly") {
          axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM')
        }  else if (xAxisValue === 'date' && time === "weekly") {
          axisValue = moment.tz(value[xAxis], 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
        }
        else { axisValue = value[xAxis] }

        if (!res[axisValue]) {
          res[axisValue] = {
            [xAxisValue]: axisValue,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
            ngr: 0
          };
          agg_result.push(res[axisValue]);
        }
        res[axisValue].impressions += parseInt(value[impressions]) || 0;
        res[axisValue].clicks += parseInt(value[clicks]) || 0;
        res[axisValue].spend += parseInt(value[spend]) || 0;
        res[axisValue].regs += parseInt(value[regs]) || 0;
        res[axisValue].ftds += parseInt(value[ftds]) || 0;
        res[axisValue].ngr += parseInt(value[ngr]) || 0;
    
        return res;
      }, {});

      agg_result = agg_result.map((obj) => {
        const impressions = obj.impressions || 0;
        const clicks = obj.clicks || 0;
        const spend = obj.spend || 0;
        const regs = obj.regs || 0;
        const ftds = obj.ftds || 0;
        const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        const ctrFix = ctr * 100
        return {
          ...obj,
          ctr: parseFloat(ctrFix).toFixed(4),
          cpc: parseFloat(cpc).toFixed(2),
          cpa: parseInt(cpa),
          cpr: parseInt(cpr),
        };
      });

      var keysToKeep = []

      if (currentType === 'mixed') {
        keysToKeep = [xAxisValue, currentMetric1, currentMetric2]
      } else if (currentType === 'table') {
        keysToKeep = [xAxisValue, 'impressions', 'clicks', 'ctr', 'cpc', 'spend', 'regs',  'cpa', 'cpr', 'ftds']
      } else { keysToKeep = [xAxisValue, currentMetric1] }


      const filteredResult = agg_result.map(item => {
        const filteredItem = {};
      
        keysToKeep.forEach(key => {
          if (item.hasOwnProperty(key)) {
            filteredItem[key] = item[key];
          }
        });
      
        return filteredItem;
      });

      if (xAxisValue === 'date' && time === "monthly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      
      if (xAxisValue === 'date' && time !== 'monthly') {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM-DD');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      else {filteredResult.sort((a, b) => a[xAxisValue] - b[xAxisValue]);}

      return filteredResult

}