import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
        GridToolbarColumnsButton,
        GridToolbarFilterButton,
        GridToolbarDensitySelector,
        GridToolbarExport 
       } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';
import AttModels from '../AttModels';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AttTable(props) {

  const dataPropBase = props.dataProp
  const models = props.models
  const columnWidth = props.columnWidth
  const theme = props.theme
  const model1 = models.model1
  const model2 = models.model2
  const model3 = models.model3
  const dynamicFilter = props.dynamicFilter
  const filterParams = props.filterParams
  const filter1name = filterParams.filter1name
  const filter2name = filterParams.filter2name
  const filter3name = filterParams.filter3name

  const [aggregationModel, setAggregationModel] = useState({
    spend: 'sum',
    ftds: 'sum',
    cpa: 'cpa',
    clicks: 'sum',
    impressions: 'sum',
    regs: 'sum',
    [filter1name]: 'total',
    [filter2name]: 'total',
    [filter3name]: 'total',
    adgroup_name: 'total',
    keyword: 'total',
    [`ftds_${model1}`] : 'sum',
    [`react_${model1}`] : 'sum',
    total_conv: 'sum',
    [`ngr_${model1}`] : 'sum',
    arpu: 'arpu',
    deposits: 'sum'
  });

  const dataProp2 = AttModels(models, dataPropBase, dynamicFilter)


  let dataProp = dataProp2
  dataProp = dataProp2.map((obj) => {
    const clicks = obj.clicks
    const dim = obj[dynamicFilter]
    const ftdsBase = `ftds_${model1}`
    const ngrBase = `ngr_${model1}`
    const reactBase = `react_${model1}`
    const spend = parseFloat(obj.spend)
    const ftds = (obj[ftdsBase]) || 0;
    const ngr = parseFloat(obj[ngrBase]);
    const regs = obj.regs_ft_lifetime;
    const impressions = obj.impressions;
    const deposits = obj.deposits;
    const react_45 = (obj[reactBase]) || 0;
    const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : parseFloat(spend / (ftds + react_45));
    const roas = spend === 0 || ngr === 0 || isNaN(ngr) ? 0 : parseFloat(ngr / spend);
    const arpu = ngr === 0 || ftds === 0 || isNaN(ftds) ? 0 : parseFloat(ngr / ftds);
    return {
      [dynamicFilter]: dim || "NULL",
      impressions: impressions ||0,
      clicks: clicks || 0,
      spend: parseFloat((spend).toFixed(2)) || 0,
      regs: regs || 0,
      [ftdsBase] : parseFloat((ftds).toFixed(2)) || 0,      
      [ngrBase]: parseFloat((ngr).toFixed(2)) || 0,
      [reactBase]: parseFloat((react_45).toFixed(2)) || 0,
      total_conv: (react_45 || 0) + (ftds || 0),
      cpa: parseFloat((cpa).toFixed(2)) || 0,
      arpu: parseFloat((arpu).toFixed(2)),
      deposits: deposits || 0

    };
  });

  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}


  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    clicks: false,
    impressions: false,
    regs: false,
    channel: true,
    [`ftds_${model1}`] : true,
    [`react_${model1}`] : false,
    total_conv: true,
    [`ngr_${model1}`] : false,
    arpu: false,
    deposits: false
  });

  const valueFormat = (value, field) => {
    if (field === 'cpa' || field === 'spend' || field.includes("ngr") || field.includes("arpu")) {
        return `€${value}`;
    } else if (field === 'spend_rate') {
        const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
        return `${percent}%`;
    }
    return value;
};

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labels = Object.keys(labelObject).map((key , index) => {
        return { 
        field: key, 
        headerName: key, 
        minWidth: windowWidth * columnWidth * 1.25, 
        flex: 1, 
        headerAlign: 'center', 
        headerClassName: 'table-header', 
        align: 'center',
        type: 'number',
        renderCell: (params) => {
            const { value, field } = params;
            const heatmapColor = calculateHeatmapColor(value, { field });
            if (index <= 1) {
                return (
                  <div
                    className="heatmap-cell"
                    style={{
                      backgroundColor: heatmapColor,
                      padding: '7%',
                      color: theme.color,
                      width: '100%',
                      fontSize: responseText * 0.8
                    }}
                  >
                    {valueFormat(params.formattedValue, params.field)}
                  </div>
                );
              } else if (params.aggregation) {
                return (
                    <div
                        className="heatmap-cell-aggregated"
                        style={{
                            fontWeight: 'bold', // Add your custom font weight
                            display: 'flex', // Apply flex styles to center content vertically/horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%', // Ensure the cell takes up the full height
                            width: '100%',
                            color: theme.color,
                            paddingLeft: 0,
                            fontFamily: 'Electrolize',
                            fontSize: responseText * 0.8,
                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            } else return (
                <div
                  className="heatmap-cell"
                  style={{
                    backgroundColor: heatmapColor,
                    padding: '10%',
                    width: '100%' ,
                    fontSize: responseText * 0.8
                    
                  }}
                >
                  {valueFormat(params.formattedValue, params.field)}
                </div>
              );
            },
        
        };
      });  

    const columnRanges = {};
    Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
  const { min, max } = columnRanges[column.field];
  const range = max + 1- min;

// Define input colors for the heatmap in RGB format
  const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
  const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

  const relativeValue = (value - min) / range;
  const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
  const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
  const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

  return `rgba(${red},${green},${blue},0.9)`;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{color: theme.color}}>
      <GridToolbarColumnsButton sx={{color: theme.color}}/>
        <GridToolbarFilterButton sx={{color: theme.color}}/>
        <GridToolbarDensitySelector sx={{color: theme.color}}/>
        <GridToolbarExport sx={{color: theme.color}}/>
    </GridToolbarContainer>
  );
}

function calculateCPA (spend, ftd) {
  const cpa =  (spend / ftd)

  return Math.round(cpa)
}

function calculateARPU (ngr, ftd) {
  const arpu =  (ngr / ftd)

  return Math.round(arpu)
}

function calculateTotal (spend, ftd) {
  return "TOTAL"
  }

const cpa = {
  label: '',
  getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
  apply: ({ values }) => {
    let spend = 0;
    let ftd = 0;
    values.forEach((value) => {
      if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
      }
    });
    return calculateCPA(spend, ftd);
  },
  columnTypes: ['number'],
};

const arpu = {
  label: '',
  getCellValue: ({ row }) => ({ ngr: row[`ngr_${model1}`], ftd: row[[`ftds_${model1}`]] }),
  apply: ({ values }) => {
    let ngr = 0;
    let ftd = 0;
    values.forEach((value) => {
      if (value) {
          ngr += value.ngr || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
      }
    });
    return calculateARPU(ngr, ftd);
  },
  columnTypes: ['number'],
};

  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  const largeRows = ((windowHeight / 60).toFixed(0))

  return (
    <Box
    style={{
      width: '100%',
      height : windowHeight * 0.82, 
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize',
 
      
    }}
      sx={{
        '& .table-header': { 
          headerAlign: 'center', 
          align : 'center' , 
          fontFamily : 'Electrolize' , 
          color: theme.color, 
          fontSize: responseText, },
          '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
          '& .MuiTablePagination-root' : {color: theme.color},
        width: '100%'}}
    >
      <DataGridPremium 
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow}
        slots={{ toolbar: CustomToolbar  }}
        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
                onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
                aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                cpa,
                total,
                arpu
                }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(largeRows) }           
          },
        sorting: {
        sortModel: [{ field: 'spend', sort: 'desc' }],
        },
        }}
        
        />
    </Box>
  );
}