import React, { useEffect, useRef } from 'react';
import { Dimensions } from 'react-native'
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import WebFont from 'webfontloader';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const BarChart = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.dataProp
  const labels = props.labels
  const theme = props.theme
  const metric = props.metric
  const chartTitle = props.timeFrame

  const axisMin = 0;
  const axisMinBase = axisMin * 0.95

  let axisMinValue;

  if (dataProp[0] === dataProp[1]) {
    axisMinValue = 0
  } else {
    axisMinValue = axisMinBase
  }

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.012
  } else {responseText = windowHeight * 0.012}

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);


  const data = {
    labels: labels,
    datasets: [
      {
        label: chartTitle,
        data: dataProp,
        backgroundColor: theme.backgroundColor2,
        borderRadius: 5,
      },
    ],
  };

  
const options = {
  maintainAspectRatio: false,
  scales: {
    y: {
      min: axisMinValue,
      grid: {
        display: false, // Remove the gridlines on the y-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for y-axis labels
          weight: 'bold', // Set the font weight for y-axis labels
          family : 'Electrolize',
        },
      },
    },
    x: {
      grid: {
        display: false, // Remove the gridlines on the x-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for x-axis labels
          family : 'Electrolize'
        },
      },
    },
  },
  tooltips: {
    enabled: true,    
  },
  plugins: {
    legend: {
      display: true,
      text: 'Chart Title',
      labels: {
        color : theme.color,
        // This more specific font property overrides the global property
        font: {
            size: responseText,
            family : 'Electrolize'
        }
    }
    },
    tooltip: {
      enabled: true,
    },
    title: {
      display: false,
      text: 'Chart Title',
      font: {
        size: responseText, // Set the font size for the chart title
        weight: 'bold', // Set the font weight for the chart title

      },
    },
  },
};

  return <Bar ref={chartRef} data={data} options={options} style={{fontFamily : 'Electrolize'}} />;
};

export default BarChart;
