import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Dimensions } from 'react-native'

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const MenuProps = {
    MenuListProps: {
        disablePadding: true
    },
    PaperProps: {
        style: {
            maxWidth: 250,
            maxHeight: '85%'
        },

    },
};


export default function FiltersDynamicMulti(props) {
    const [filterList, setFilterList] = useState([])
    const [currentSelected, setCurrentSelected] = useState('')
    const filterList2 = props.filterList
    const setFilterList2 = props.setFilterList
    const currentFilterValue = props.currentFilterValue
    const setCurrentValue = props.setCurrentValue
    const filterParams = props.filterParams
    const filterIndex = props.filterIndex
    const filterIndexAdj = filterIndex + 1
    const propObject = props.propObject
    const itemListBase = props.listValues
    const itemTitle = props.type
    const setList = props.setList
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const large = props.large
    const singleModel = props.singleModel
    const doubleFilter = props.doubleFilter
    const itemList = ['Total', ...itemListBase]
    const [selectedValue, setSelectedValue] = useState(null)

    const currentRef = `filter${filterIndexAdj}List`
    const filterState = filterList2[currentRef]
    const lastSelect = filterState[filterState.length - 1]
    const currentArray = filterParams[currentRef]
    
    let filterStateValue;


    if (lastSelect === 'Total') {
      filterStateValue = ['Total']
    } else {
      filterStateValue = filterState.filter(item => item !== 'Total');
    }

    let filterWidth;

    if (doubleFilter && singleModel && !large) {
      filterWidth = windowWidth * 0.20
    } else if (doubleFilter && singleModel && large) {
      filterWidth = windowWidth * 0.33
    } else if (!singleModel && !large) {
      filterWidth = windowWidth * 0.18
    } else if (!singleModel && large) {
      filterWidth = windowWidth * 0.18
    } else if (singleModel && large) {
      filterWidth = windowWidth * 0.24
    } else {
      filterWidth = windowWidth * 0.16
    }

    function handleClose(event) {

        let defaultArray;

        if (filterList.length === 0) {
            defaultArray = 'Total'
        } else { defaultArray = filterList }

        if (!selectedValue) {
          //do nothing
        } else {
        
          setList(filterList)
        const propUpdate = {
            ...propObject,
            [itemTitle] : filterStateValue
        }
          handleStateChange(propUpdate)
        
        //setCurrentValue(filterList)
        //if (filterList.length === 0) {
          //setCurrentValue(filterList)
        //}

        const filterCopy = {
          ...filterList2,
          [currentRef] : filterStateValue
          }
        //if (lastSelect && filterList.length > 0) {
          setFilterList2(filterCopy)
          //setFilterList(filterStateValue)
        //}
        }
        
        
    }


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        let fallbackValue;

        if(!value) {
          fallbackValue = 'Total'
        } else {
          fallbackValue = value
        }
        setSelectedValue(value)

        if (!value) {
          //do nothing
        } else {

        setFilterList(          
            // On autofill we get a stringified value.
            
            typeof value === 'string' ? value.split(',') : fallbackValue,
            //setCurrentSelected(value)
        );

        const filterCopy = {
          ...filterList2,
          [currentRef] : typeof value === 'string' ? value.split(',') : value
        }
        
          setFilterList2(filterCopy)
          //setFilterList(filterCopy)
      }


        

          
        
    };

    let responseHeight;

  if (windowWidth < 1441) {
    responseHeight = windowHeight * 0.045
  } else if (windowWidth > 2000 && large === 1) {
    responseHeight = windowHeight * 0.035
  }
   else if (windowWidth > 2000) {
    responseHeight = windowHeight * 0.03
  } else {responseHeight = windowHeight * 0.0415}

  let responseMargin;

  if (windowHeight < 800 && large === 1) {
    responseMargin = '-1%'
  }  else if (windowWidth > 2000) {
    responseMargin = '-0.8%'
  } else if (windowHeight > 800 && large === 1) {
    responseMargin = '-1.2%'
  }  else if (windowHeight > 800) {
    responseMargin = '-1%'
  } else if (windowWidth < 1441 && large === 1) {
    responseMargin = '-3%'
  } else if (windowWidth < 1441) {
    responseMargin = '-1%'
  } else  {responseMargin = '-1%'}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000 && large === 1) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.01
  } else {responseText = windowHeight * 0.017}

    return (
        <div >
            <FormControl sx={{ width: filterWidth,
                                fontFamily : 'Electrolize' , 
                                color : theme.color,
                                borderWidth : 0.1,
                                borderColor: theme.backgroundColor2,
                                alignItems: 'center',
                                justifyContent: 'center' }} >
                <InputLabel id="demo-multiple-checkbox-label"
                    style={{
                        fontFamily: 'Electrolize',
                        color : theme.color,
                        borderWidth : 1,
                        borderColor: theme.backgroundColor2,
                        fontSize: responseText,
                        marginTop: responseMargin,  
                        textTransform: 'capitalize'
                    }}
                >{itemTitle.replace("_", " ")}</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterStateValue}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    style={{
                        width : '100%' ,
                        left : 0, 
                        fontFamily : 'Electrolize', 
                        color : theme.color , 
                        backgroundColor : theme.backgroundColor,
                        border: `1px solid ${theme.backgroundColor2}`,
                        height: 45
                    }}


                >
                    {itemList.map((name) => (
                        <MenuItem key={name} value={name} style={{
                            width : '100%',
                            fontFamily : 'Electrolize', 
                            color : theme.color , 
                            backgroundColor : theme.backgroundColor,
                            borderWidth : 1,
                            border: `1px solid grey`,
                        }}>
                            <Checkbox
                                sx={{color: theme.color}}
                                checked={filterStateValue.indexOf(name) > -1}
                                />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}