import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

const windowWidth = Dimensions.get('window').width;


export default function OptionsGroupingDeleteModal(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const deleteitem = props.deleteItem
    const child = props.child
    const currentGroup = props.currentGroup?.dataProp || []



    const [textValue, setTextValue] = useState("")

    const keys = [...new Set(currentGroup.flatMap(Object.keys))];

    const options = keys.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      ));


    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const saveHandler = (newDim) => {

        deleteitem(newDim)

        setOpen(false)

    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Delete Item</DialogTitle>
                    <DialogContent sx={{
                        //border: '2px blue solid',
                        //width: windowWidth * 0.9
                    }}>
                        <Box component="div" sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <Box component="div" sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                            }}>
                                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, width: '100%', fontFamily: 'Electrolize', }}>
                                    <InputLabel id="demo-dialog-select-label">Select Item</InputLabel>
                                    <Select
                                        labelId="demo-dialog-select-label"
                                        id="demo-dialog-select"
                                        value={textValue}
                                        sx={{fontFamily: 'Electrolize'}}


                                        onChange={(event) => setTextValue(event.target.value)}
                                        input={<OutlinedInput label="Select Item" sx={{fontFamily: 'Electrolize'}}/>}
                                    >
                                        {options}
                                    </Select>

                                </FormControl>

                            </Box>
                            <View style={{ width: '100%', alignItems: 'end', justifyContent: 'center', padding: '2px' }}>

                                <Button variant="contained"
                                    sx={{
                                        backgroundColor: theme.backgroundColor2,
                                        color: 'white',
                                        fontFamily: 'Electrolize',
                                        fontWeight: 600
                                    }}
                                onClick={() => saveHandler(textValue)}

                                >
                                    SAVE
                                </Button>



                            </View>


                        </Box>

                    </DialogContent>
                </View>

            </Dialog>
        </div>

    );
}