import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import FilterPanelV3 from '../../Components/FilterPanelV3';
import CohortDepositChart from './CohortDepositChart.js';
import CohortDepositRunning from './CohortDepositRunning.js';
import CohortDepositCube from './CohortDepositCube.js';
import { postToSignedUrl } from '../../UserConfigs/AwsSigner.js';
import signRequest from '../../UserConfigs/AwsSigner.js';
import LoadingComponent from '../../Components/Loader.js';
import ParseDimensions from '../../CubeFunctions/ParseDimensions.js';
import ParseDimensionsAtt from '../../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import CubeDailyFunction from '../../DailySummary/CubeFunctions/CubeDailyFunction.js';
import NoDataContainer from '../../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const CohortDepositPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_cohorts";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: "2022-07-01",
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: "2022-07-01",
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
        };

        setFilters(filterUpdate)

    };

    const cubeDims = ['cohort_month', 'monthsfromftd']
    const dimList = [filter1name, filter2name, filter3name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const filterSql = [dim1Value, dim2Value, dim3Value]

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "timestamp",
          "dimensions": cubeDims,
          "measures": [
            "ngr",
            "cost",
            "ftds",
            "deposit_amount",
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "timestamp",
          "dimensions": dimList,
          "measures": [
            "ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
        requestDimData(dimQuery, config_url, support_key, support_id, 'POST')
      }, [configArray, filters])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponse = configResponse.data
    const dimCubeResponse = dimResponse.data

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)
    const uniqueFilter2 = getUniqueValues(dimCubeResponse, filter2name)
    const uniqueFilter3 = getUniqueValues(dimCubeResponse, filter3name)

    const uniqueBrands = getUniqueValues(dimCubeResponse, filter1name);
    const uniqueChannels = getUniqueValues(dimCubeResponse, filter2name);
    const uniqueTypes = getUniqueValues(dimCubeResponse, filter3name);

    const agg_cohorts = CohortDepositRunning(cubeResponse, cubeTable)

    if (agg_cohorts.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const panelMain = {

        width: '100%',
        height: '100%',
        minHeight: windowHeight * 0.931,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterPanelV3
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueBrands={uniqueBrands}
                            uniqueChannels={uniqueChannels}
                            uniqueTypes={uniqueTypes}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            filterParams={filterParams}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    <View style={styles.cohortVis}>
                    <CohortDepositChart
                        
                        theme={theme}
                        agg_cohorts={agg_cohorts}
                    />
                    </View>
                </View>

            </View>
        </View>

    )

}

export default CohortDepositPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    },
    cohortVis: {
        marginTop: '0%',
        width: '100%',
        alignItems: 'center'
    }
})