import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;

export default function OptionsGroupingHomeModal(props) {
    const open = props.open
    const theme = props.theme
    const setCurrentView = props.setCurrentView
    
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            //setOpen(false);
        }
        setCurrentView('Dimensions')
    };

    const pageHandler = (page) => {
        setCurrentView(page)

    }

    const ColorButtonPrimary = styled(Button)(({ theme }) => ({
        color: 'white',
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                //sx={{width: '100%', height: '100%'}}

            >
                <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Custom Grouping</DialogTitle>
                <DialogContent >
                    <Box component="form" sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        

                    }}>
                        <View style={{
                            width: '33%',
                            height: '100%',
                            padding: '2%',
                            alignItems: 'center',
                        }}>
                        <ColorButtonPrimary
                            onClick={() => pageHandler('Dimensions')}
                            variant="contained"
                            //sx={{ fontWeight: 600, height: windowWidth * 0.075, width: windowWidth * 0.1, textAlign: 'center', fontFamily: 'Electrolize' }}
                            theme={theme}
                        >
                            Custom Dimensions
                        </ColorButtonPrimary>
                        </View>
                        <View style={{
                            width: '33%',
                            height: '100%',
                            padding: '2%',
                            alignItems: 'center',
                            marginLeft: '2%'
                        }}>
                        <ColorButtonPrimary
                            onClick={() => pageHandler('Dimensions')}
                            variant="contained"
                            //sx={{ fontWeight: 600, height: windowWidth * 0.075, width: windowWidth * 0.1, textAlign: 'center', fontFamily: 'Electrolize',  }}
                            disabled={true}
                            theme={theme}
                        >
                            Custom Dimensions
                        </ColorButtonPrimary>
                        </View>
                        <View style={{
                            width: '33%',
                            height: '100%',
                            padding: '2%',
                            alignItems: 'center',
                            marginLeft: '2%'
                        }}>
                        <ColorButtonPrimary
                            //onClick={handleClose}
                            variant="contained"
                            /*sx={{ fontWeight: 600, 
                                height: windowWidth * 0.075, 
                                width: windowWidth * 0.1, 
                                textAlign: 'center', 
                                fontFamily: 'Electrolize',                                
                             }}*/
                            theme={theme}
                            disabled={true}
                        >
                            Request Backend Updates
                        </ColorButtonPrimary>
                        </View>


                    </Box>

                </DialogContent>

            </Dialog>
        </div>

    );
}