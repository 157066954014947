export default function OptionsGroupingPreviewCase(data, groupingData, type) {

    const dimensionsArray = groupingData.data.custom_groupings.custom_dimensions; // Array of custom_dimensions

    // Helper function to process a single dimension and return a new object with the added field
    function forDimension(dimensions, baseData) {
        const dimensionName = Object.keys(dimensions)[0]; // New field name
        const dimensionValues = Object.values(dimensions); // Array of condition sets for this dimension
        const elseValue = dimensionValues[0][0].else

        // Initialize the field to the original value if it exists, or null if not found
        let dimensionResult = baseData[dimensionName] || null;

        // Iterate through condition sets and apply the first matching condition
        for (const conditions of dimensionValues) {
            let matchFound = false;

            for (const condition of conditions) {
                const {
                    value: conditionValue,
                    operator: conditionOperator,
                    return_value: conditionReturnValue,
                    dimension: conditionDimension,
                } = condition;

                // Check if conditionDimension exists in baseData
                if (baseData.hasOwnProperty(conditionDimension)) {
                    const testValue = baseData[conditionDimension];

                    // Apply condition based on operator
                    const isMatch =
                        (conditionOperator === 'equals' && testValue === conditionValue) ||
                        (conditionOperator === 'contains' && testValue.includes(conditionValue));

                    if (isMatch) {
                        dimensionResult = conditionReturnValue;
                        matchFound = true;
                        break;
                    } else {
                        dimensionResult = baseData[elseValue];
                    }
                }
            }
            if (matchFound) break;
        }

        // Return a new object with the additional field
        return { ...baseData, [dimensionName]: dimensionResult };
    }

    // Process each entry in data
    const results = data.map(entry => {
        let processedEntry = { ...entry }; // Copy each entry to avoid mutation

        // Process each dimension independently and merge the results
        dimensionsArray.forEach(dimension => {
            processedEntry = forDimension(dimension, processedEntry);
        });

        return processedEntry; // Add processed entry to results array
    });

    return results;
}