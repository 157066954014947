import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import ExecSummaryMetricContent from './ExecSummaryMetrics/ExexSummaryMetrics';
import WebFont from 'webfontloader';
import AttModelSelect from '../AttributionEnhanced/AttModelSelect';
import TableChartIcon from '@mui/icons-material/TableChart';
import ExecSummaryMetricsSummaryParse from './ExecSummaryMetrics/ExecSummaryMetricsSummaryParse';
import ExecSummaryMetricsParse from './ExecSummaryMetrics/ExecSummaryParse';
import ExecSummaryMetricsFtdParse from './ExecSummaryMetrics/ExecSummaryMetricsFtdParse';
import ExecSummaryMetricsRegParse from './ExecSummaryMetrics/ExecSummaryMetricsRegParse';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CasinoIcon from '@mui/icons-material/Casino';


const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const ExecSummaryContent = (props) => {

    const currentMetrics = props.currentMetrics
    const dataProp = props.dataProp
    const aggResult = dataProp.aggResult
    const models = dataProp.models
    const theme = props.theme

    const dynamicFilter = props.dynamicFilter
    const modelHandler = props.modelHandler
    const setOpen = props.setOpen
    const attModalOpen = props.attModalOpen
    const filterParams = props.filterParams
    const [panelContent, setPanelContent] = useState('summary')
    const [brandFilter, setBrandFilter] = useState('Total')
    const [countryFilter, setCountryFilter] = useState(['SWE'])

    const segResult = ExecSummaryMetricsParse(aggResult)
    const summaryAgg = segResult['Summary']
    const ftdAgg = segResult['FTD']
    const regConv = segResult['Reg']
    const firstPlayedFtd = segResult['First Product FTD']
    const ngrProduct = segResult['Product'].productNgr
    const ggrProduct = segResult['Product'].productGgr
    const activesProduct = segResult['Product'].productActives
    const betProduct = segResult['Product'].productBet
    const ngrVip = segResult['VIP'].vipNgr
    const activesVip = segResult['VIP'].vipActives
    const depositsVip = segResult['VIP'].vipDeposits
    const depositorsVip = segResult['VIP'].vipDepositors
    const bonusVip = segResult['VIP'].vipBonus
    const ggrProvider = segResult['Provider'].providerGgr
    const betProvider = segResult['Provider'].providerBet


    const summaryData = ExecSummaryMetricsSummaryParse(summaryAgg, brandFilter, countryFilter)
    const ftdData = ExecSummaryMetricsFtdParse(ftdAgg, brandFilter, countryFilter)
    const regData = ExecSummaryMetricsFtdParse(regConv, brandFilter, countryFilter)
    const regConvData = ExecSummaryMetricsRegParse(regConv, ftdAgg, brandFilter, countryFilter)
    const firstPlayedFtdData = ExecSummaryMetricsFtdParse(firstPlayedFtd, brandFilter, countryFilter)
    const ngrProductData = ExecSummaryMetricsFtdParse(ngrProduct, brandFilter, countryFilter)
    const ggrProductData = ExecSummaryMetricsFtdParse(ggrProduct, brandFilter, countryFilter)
    const activesProductData = ExecSummaryMetricsFtdParse(activesProduct, brandFilter, countryFilter)
    const betProductData = ExecSummaryMetricsFtdParse(betProduct, brandFilter, countryFilter)
    const ggrMargin = ExecSummaryMetricsRegParse(betProduct, ggrProduct, brandFilter, countryFilter)

    const ngrVipData = ExecSummaryMetricsFtdParse(ngrVip, brandFilter, countryFilter)
    const activesVipData = ExecSummaryMetricsFtdParse(activesVip, brandFilter, countryFilter)
    const arpuVipData = ExecSummaryMetricsRegParse(activesVip, ngrVip, brandFilter, countryFilter)
    const depositsVipData = ExecSummaryMetricsFtdParse(depositsVip, brandFilter, countryFilter)
    const depositorsVipData = ExecSummaryMetricsFtdParse(depositorsVip, brandFilter, countryFilter)
    const bonusVipData = ExecSummaryMetricsFtdParse(bonusVip, brandFilter, countryFilter)
    const bonusRateVipData = ExecSummaryMetricsRegParse(depositsVip, bonusVip, brandFilter, countryFilter)

    const ggrProviderData = ExecSummaryMetricsFtdParse(ggrProvider, brandFilter, countryFilter)
    const betProviderData = ExecSummaryMetricsFtdParse(betProvider, brandFilter, countryFilter)



    function navHandler (content, brand) {
        setPanelContent(content)
        setBrandFilter(brand)

    }


    const execDataProp = [
        {
            id: "summaryData",
            title: "Summary - All Metrics",
            dataProp: summaryData,
            isAllPercent: false
        },
        {
            id: "ftdData",
            title: "Summary - FTDs by Channel",
            dataProp: ftdData,
            isAllPercent: false
        },
        {
            id: "regData",
            title: "Summary - Regs by Channel",
            dataProp: regData,
            isAllPercent: false
        },
        {
            id: "regConv",
            title: "Summary - Conv by Channel",
            dataProp: regConvData,
            isAllPercent: true
        },
        {
            id: "firstPlayedFtdData",
            title: "FTD# by First Played Product",
            dataProp: firstPlayedFtdData,
            isAllPercent: false
        },
        {
            id: "ngrProductData",
            title: "NGR (Product) €",
            dataProp: ngrProductData,
            isAllPercent: false
        },
        {
            id: "ggrProductData",
            title: "GGR (Product) €",
            dataProp: ggrProductData,
            isAllPercent: false
        },
        {
            id: "activesProductData",
            title: "Actives# (Product)",
            dataProp: activesProductData,
            isAllPercent: false
        },
        {
            id: "betProductData",
            title: "Bet (Product) €",
            dataProp: betProductData,
            isAllPercent: false
        },
        {
            id: "ggrMargin",
            title: "GGR Margin (Product) %",
            dataProp: ggrMargin,
            isAllPercent: true
        },
        {
            id: "ngrVipData",
            title: "NGR (VIP) €",
            dataProp: ngrVipData,
            isAllPercent: false
        },
        {
            id: "arpuVipData",
            title: "ARPU (VIP) €",
            dataProp: arpuVipData,
            isAllPercent: false
        },
        {
            id: "activesVipData",
            title: "Actives# (VIP)",
            dataProp: activesVipData,
            isAllPercent: false
        },
        {
            id: "depositsVipData",
            title: "Deposits (VIP) €",
            dataProp: depositsVipData,
            isAllPercent: false
        },
        {
            id: "depositorsVipData",
            title: "Depositors# (VIP)",
            dataProp: depositorsVipData,
            isAllPercent: false
        },
        {
            id: "bonusVipData",
            title: "Bonus (VIP) €",
            dataProp: bonusVipData,
            isAllPercent: false
        },
        {
            id: "bonusRateVipData",
            title: "Total Bonus / Deposits (VIP) %",
            dataProp: bonusRateVipData,
            isAllPercent: true
        },
        {
            id: "ggrProviderData",
            title: "GGR (Provider) €",
            dataProp: ggrProviderData,
            isAllPercent: false
        },
        {
            id: "betProviderData",
            title: "Bet (Provider) €",
            dataProp: betProviderData,
            isAllPercent: false
        }
    ]

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    let responseHeight;

    if (windowWidth < 1441) {
        responseHeight = windowHeight * 0.11
    } else if (windowWidth > 2000) {
        responseHeight = windowHeight * 0.08
    } else { responseHeight = windowHeight * 0.08 }


    const panelMain = {

        width: '100%',
        height: responseHeight,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
    }

    const panelChild = {
        flex: 1,
        height: windowHeight * 0.2,
        width: windowWidth,
        borderWidth: 1,
        borderColor: 'grey',

        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        flexDirection: 'row',
    }


    const tablePanel = {
        width: '85%',
        height: '100%',
        borderColor: 'blue',
        borderWidth: 0
    }

    const chartPanel = {
        width: '15%',
        height: '100%',
        borderColor: 'blue',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const chartChild = {
        width: '100%',
        height: '20%',
        borderColor: 'grey',
        borderWidth: 1,
        padding: '1%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
        flexDirection: 'column'
    }

    const chartChildContent = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const chartChildTitle = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    }

    const titleText = {
        fontFamily: 'Electrolize',
        color: theme.color,
        //fontSize: windowWidth * 0.015
    }

    const iconStyle = {
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.06
    }

    const iconStyleDisabled = {
        color: 'grey',
        fontSize: windowWidth * 0.06
    }

    const chartChildContentSelected = {
        width: '100%',
        height: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
        color: 'white',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const iconStyleSelected = {
        color: theme.backgroundColor,
        fontSize: windowWidth * 0.06
    }

    const titleTextSelected = {
        fontFamily: 'Electrolize',
        color: 'white',
        //fontSize: windowWidth * 0.015
    }

    const chartChildTitleSelected = {
        width: '100%',
        height: '20%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor2,
    }

        return (
            <View style={styles.container}>
                <View style={panelChild}>
                    <View style={tablePanel}>
                    <ExecSummaryMetricContent
                        dataProp={execDataProp}
                        theme={theme}
                        models={models}
                        filterParams={filterParams}
                        dynamicFilter={dynamicFilter}
                        currentMetrics={currentMetrics}
                        countryFilter={countryFilter}
                        setCountryFilter={setCountryFilter}
                        />
                    </View>
                    <View style={chartPanel}>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => navHandler('summary', 'Total')}
                                style={panelContent === 'summary' ? chartChildContentSelected : chartChildContent}>
                                <TableChartIcon style={panelContent === 'summary' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'summary' ? chartChildTitleSelected : chartChildTitle}>
                                <Text style={panelContent === 'summary' ? titleTextSelected : titleText}>
                                    Summary
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => navHandler('Lucky', 'Lucky')}
                                style={panelContent === 'Lucky' ? chartChildContentSelected : chartChildContent}>
                                <TableChartIcon style={panelContent === 'Lucky' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'Lucky' ? chartChildTitleSelected : chartChildTitle}>
                            <Text style={panelContent === 'Lucky' ? titleTextSelected : titleText}>
                                    Lucky
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => navHandler('Happy', 'Happy')}
                                style={panelContent === 'Happy' ? chartChildContentSelected : chartChildContent}>
                                <TableChartIcon style={panelContent === 'Happy' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'Happy' ? chartChildTitleSelected : chartChildTitle}>
                            <Text style={panelContent === 'Happy' ? titleTextSelected : titleText}>
                                    Happy
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <Pressable
                                onPress={() => navHandler('Flax', 'Flax')}
                                style={panelContent === 'Flax' ? chartChildContentSelected : chartChildContent}>
                                <TableChartIcon style={panelContent === 'Flax' ? iconStyleSelected : iconStyle} />
                            </Pressable>
                            <View style={panelContent === 'Flax' ? chartChildTitleSelected : chartChildTitle}>
                            <Text style={panelContent === 'Flax' ? titleTextSelected : titleText}>
                                    Flax
                                </Text>
                            </View>
                        </View>
                        <View style={chartChild}>
                            <View
                                //onPress={() => setPanelContent('summary')}
                                style={chartChildContent}>
                                <TableChartIcon style={iconStyleDisabled} />
                            </View>
                            <View style={chartChildTitle}>
                                <Text style={titleText}>

                                </Text>
                            </View>
                        </View>



                    </View>
                </View>
                <AttModelSelect
                    open={attModalOpen}
                    setOpen={setOpen}
                    modelHandler={modelHandler}
                    models={models}
                />


            </View>

        )

    }

    export default ExecSummaryContent

    const styles = StyleSheet.create({
        container: {
            borderWidth: 0,
            borderColor: 'blue',
            height: (windowHeight * 0.93),
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'Electrolize'
        }
    })