import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import OptionsGroupingLoader from './OptionsGroupingLoader';
import OptionsGroupingDefinitionsView from './OptionsGroupingDefinitionsView';
import OptionsGroupingEditModal from './OptionsGroupingEditModal';

const OptionsGroupingDefinitions = (props) => {

    const theme = props.theme
    const updateArray = props.updateArray
    const currentMetrics = props.currentMetrics
    const cubeTable = props.cubeTable
    const configArray = props.configArray
    const customGroupings = configArray.customGroupings
    const customModel = configArray.customModel
    const baseResponse = {
        data: {
            custom_groupings: customGroupings,
            custom_model: customModel
        }
    }
    const client = configArray.clientID
    const clientMapId = configArray.clientMapId
    const support_url = configArray.config_url
    const support_key = configArray.support_key
    const support_id = configArray.support_id
    const auth0_user_id = configArray.auth0_user_id
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false)

    const saveArray = {
        client,
        clientMapId,
        support_url,
        support_key,
        support_id,
        auth0_user_id
    }

    const [groupingData, setGroupingData] = useState(baseResponse)
    const [saveLoading, setSaveLoading] = useState(false)
    const [saveResponse, setSaveResponse] = useState([])

    const saveStateArray = {
        saveLoading,
        setSaveLoading,
        saveResponse,
        setSaveResponse
    }  

    return (
        <View style={styles.container}>

            <View style={styles.bodyContainer}>
                <OptionsGroupingDefinitionsView
                    theme={theme}
                    groupingData={groupingData}
                    edit={edit}
                    setEdit={setEdit}
                />
            </View>
            <OptionsGroupingLoader
                theme={theme}
                open={loading}
                message={'Retrieving Configs...'}
            />
            <OptionsGroupingEditModal
                theme={theme}
                open={edit}
                setOpen={setEdit}
                groupingData={groupingData}
                setGroupingData={setGroupingData}
                saveArray={saveArray}
                saveStateArray={saveStateArray}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}
                updateArray={updateArray}
                />

        </View>
    )

}



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    headerContainer: {
        width: '100%',
        height: '10%',
        borderWidth: 0,
        borderColor: 'grey',
        flexDirection: 'row',
    },
    bodyContainer: {
        width: '100%',
        height: '100%',
    }
})

export default OptionsGroupingDefinitions