import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import OptionsGroupingEditDefinitions from './OptionsGroupingEditDefinitions';

const windowWidth = Dimensions.get('window').width;


export default function OptionsGroupingEditModal(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const groupingData = props.groupingData
    const cubeTable = props.cubeTable
    const currentMetrics = props.currentMetrics
    const setCurrentView = props.setCurrentView
    const setGroupingData = props.setGroupingData
    const saveArray = props.saveArray
    const saveStateArray = props.saveStateArray
    const updateArray = props.updateArray
    const [currentConfig, setCurrentConfig] = useState(groupingData)

    useEffect(() => {
        // Only update if groupingData has a value
        if (groupingData) {
            setCurrentConfig(groupingData);
        }
    }, [groupingData]);


    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{width: '100%'}}>
                <DialogTitle sx={{ fontFamily: 'Electrolize'}}>Custom Grouping</DialogTitle>
                <DialogContent sx={{
                }}>
                    <Box component="form" sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}>
                        
                        
                        <OptionsGroupingEditDefinitions
                            theme={theme}
                            groupingData={currentConfig}
                            edit={open}
                            setEdit={setOpen}
                            setCurrentConfig={setCurrentConfig}
                            setGroupingData={setGroupingData}
                            saveArray={saveArray}
                            saveStateArray={saveStateArray}
                            cubeTable={cubeTable}
                            currentMetrics={currentMetrics}
                            updateArray={updateArray}
                        />
                    </Box>

                </DialogContent>
                </View>

            </Dialog>
        </div>

    );
}