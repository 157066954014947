import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import PivotTablePanel from './PivotTablePanel';
import flexBaseConfig from './flexSettings.json'

const PivotTable = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const fmKey = configArray.fmKey
  const clientID = configArray.clientID
  const theme = props.theme
  const homeTitle = "Pivot Tables";
  const role = configArray.role
  const [lastUpdated, setLastUpdated] = useState("loading...")
  const flexConfigStorage = localStorage.getItem('flexConfig')
  const [newSettings, setNewSettings] = useState(null)
  const lastFlexUsed = localStorage.getItem('lastFlex')

  if (!flexConfigStorage) {
      localStorage.setItem('flexConfig', JSON.stringify(flexBaseConfig))
  }

  if (!lastFlexUsed) {
      localStorage.setItem('lastFlex', 'default')
  }

  
  const [currentReportConfig, setCurrentReportConfig] = useState(JSON.parse(flexConfigStorage)) || flexBaseConfig

  function extractReports(data) {
    let identifiers = [];

    data.forEach(item => {
        Object.keys(item).forEach(key => {
            identifiers.push(key);
        });
    });

    return identifiers;
}

function reportMapping(data) {
  // Initialize an object to hold the result
  let result = {};

  if (data === null) {
    return []
  }

  // Iterate over each object in the provided array
  data.forEach((item, index) => {
      // Extract the key (identifier) from each object
      Object.keys(item).forEach(key => {
          // Add the key and its index to the result object
          result[key] = index;
      });
  });

  // Return the result object
  return result;
}



  const reportMap = reportMapping(currentReportConfig)  
  const reportList = extractReports(currentReportConfig)



  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        attributionEnhanced={false}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <PivotTablePanel
        style={styles.panel}
        theme={theme}
        clientID={clientID}
        currentMetrics={currentMetrics}
        setLastUpdated={setLastUpdated}
        currentReportConfig={currentReportConfig}
        setCurrentReportConfig={setCurrentReportConfig}
        flexConfigStorage={flexConfigStorage}
        lastFlexUsed={lastFlexUsed}
        newSettings={newSettings}
        setNewSettings={setNewSettings}
        reportList={reportList}
        reportMap={reportMap}
        fmKey={fmKey}
        configArray={configArray}
      />
      
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default PivotTable