import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader';
import moment from 'moment-timezone';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import HomeComparison from './CubeFunctions/HomeComparison';
import HomePanelV3 from './HomePanelV3';
import HomeBudget from './CubeFunctions/HomeBudget';
import NoDataContainer from '../Components/NoDataContainer.js';

const HomeScreen = (props) => {

  const configArray = props.configArray
  const config_url = configArray.config_url
  const support_id = configArray.support_id
  const support_key = configArray.support_key
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const currentMetrics = configArray.currentMetrics[0]
  const clientID = configArray.clientID
  const comparisonURL = configArray.comparison_url
  const [configResponse, setConfigResponse] = useState(null)
  const [budgetResponse, setBudgetResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const clientBase = clientID.toLowerCase();
  let client;
  if (clientBase === 'all_clients') {
    client = 'stack'
  } else (client = clientBase)
  const cubeTable = clientBase + "_daily";
  const budgetTable = client + "_budget";
  const theme = props.theme
  const homeTitle = "Pattern Home";
  const role = configArray.role
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

  const cumuDates = {
    start: yesterday2.format('YYYY-MM'),
    end: moment().subtract(1, 'month').startOf('month').format('YYYY-MM')
  }

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)
  }

  const homeQuery = {

    "request_type": "query",
    "query": {
      "start": startOfLastMonth,
      "end": yesterdayProp,
      "table": cubeTable,
      "date_dim": "date",
      "dimensions": [
        "date"
      ],
      "measures": [
        "spend",
        "ftds",
        "clicks",
        "ngr"
      ],
      "custom_dimensions": [],
      "custom_dimensions_aliases": [],
      "custom_measures": [],
      "custom_measures_aliases": [],
      "filters": []
    }
}

  const budgetQuery = {

    "request_type": "query",
    "query": {
      "start": startOfLastMonth,
      "end": yesterdayProp,
      "table": budgetTable,
      "date_dim": "calendar_date",
      "dimensions": [
        //"calendar_date",
        "current_dom",
        "days_in_month",
        "days_remaining"
      ],
      "measures": [
        "actual_spend",
        "yesterday_spend",
        "budget",
        "reg",
        "ftd",
        "ngr"
      ],
      "custom_dimensions": ["FORMAT_DATE(\"%Y-%m\", calendar_date) AS month"],
      "custom_dimensions_aliases": ["month"],
      "custom_measures": [],
      "custom_measures_aliases": [],
      "filters": []
    }
  }

  const requestData = async (event_data, support_url, support_key, support_id, method) => {
    setIsLoading(true)
    setConfigResponse(null); // Clear previous response

    try {
        // First, get the signed URL
        const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);

        // Send the POST request to the signed URL
        const result = await postToSignedUrl(signedUrl, event_data, method);
        setConfigResponse(result)
        setIsLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    } finally {
        //setSaveLoading(false);
    }
};

  const requestBudgetData = async (event_data, support_url, support_key, support_id, method) => {
    setIsLoading(true)
    setBudgetResponse(null); // Clear previous response

    try {
        // First, get the signed URL
        const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);

        // Send the POST request to the signed URL
        const result = await postToSignedUrl(signedUrl, event_data, method);
        setBudgetResponse(result)
        setIsLoading(false)
    } catch (error) {
        console.error("Error posting to Lambda:", error);
    } finally {
        //setSaveLoading(false);
    }
  };

  useEffect(() => {
    requestData(homeQuery, config_url, support_key, support_id, 'POST')
    requestBudgetData(budgetQuery, config_url, support_key, support_id, 'POST')
  }, [configArray])


  if (isLoading) {
    return (
      <LoadingComponent theme={theme} />
    );
  }
  
  if (!configResponse) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!budgetResponse) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  //if (budgetResultSet.isLoading === true) {
    //return (
        //<LoadingComponent theme={theme} />
    //);
//}

  const homeData = configResponse.data
  const budgetData = budgetResponse.data

  const lastUpdated = 'loading...'

  const agg_result = homeData.map((obj) => {
    const date = obj.date;
    const spend = parseFloat(obj.spend)
    const ftds = parseInt(obj.ftds)
    const ngr = parseFloat(obj.ngr)
    const cpa = parseFloat((spend / ftds).toFixed(2))
    const clicks = parseInt(obj.clicks)
    const cpc = parseFloat((spend / clicks).toFixed(2))
    const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

    return {
        date: date,
        clicks: clicks,
        spend: spend,
        cpc: cpc,
        ftds: ftds,
        ngr: ngr,
        cpa: cpa,
        clickConv: ftdConv

    };
});


  if (agg_result.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
  }

  return (

    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
    
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
    
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <HomePanelV3
        theme={theme}
        data={agg_result}
        clientID={clientID}
        comparisonURL={comparisonURL}
        budgetArray={budgetData}
        cumuDates={cumuDates}
        currentMetrics={currentMetrics}
      />

    </View>


  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default HomeScreen