import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable
} from 'react-native'
import FiltersEnhanced from '../../ComponentsEnhanced/FiltersEnhanced';
import WebFont from 'webfontloader';
import AttEnhancedLookerModels from './AttEnhancedLookerModels';
import ParseLookerSummary from '../CubeFunctions/ParseLookerSummary';
import ParseLookerDaily from '../CubeFunctions/ParseLookerDaily';
import AttEnhancedLookerTable from './AttEnhancedLookerTable';
import AttEnhancedLookerTableDaily from './AttEnhancedLookerTableDaily';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const AttEnhancedLookerContent = (props) => {

    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const uniqueFilter4 = dataProp.uniqueFilter4
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const aggResult = dataProp.aggResult
    const models = dataProp.models
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const dynamicFilter = props.dynamicFilter
    const modelHandler = props.modelHandler
    const setOpen = props.setOpen
    const attModalOpen = props.attModalOpen
    const filterParams = props.filterParams
    const primaryMetric = props.primaryMetric
    const primaryMetricHandler = props.primaryMetricHandler
    const startDate = propObject.start
    const endDate = propObject.end

    const model1 = models.model1
    const model2 = models.model2
    const model3 = models.model3

    const aggView = ParseLookerSummary (aggResult, dynamicFilter, 'metric', model1)
    const dailyView = ParseLookerDaily (aggResult, dynamicFilter, primaryMetric, model1, startDate, endDate)

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    let responseHeight;

    if (windowWidth < 1441) {
        responseHeight = windowHeight * 0.11
      } else if (windowWidth > 2000) {
        responseHeight = windowHeight * 0.08
      } else {responseHeight = windowHeight * 0.08}


    const panelMain = {

        width: '100%',
        height: responseHeight,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const panelChild = {
        height: '88%',
        width: windowWidth,
        borderWidth: 0,
        borderColor: 'grey',

        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        flexDirection: 'column',
        
    }

    const bottomPanel = {
        width: '100%',
        height: '10%',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        flexDirection: 'column'
    }

    const tablePanel = {
        width: '100%',
        height: '50%',
        borderColor: 'blue',
        borderWidth: 0,
        padding: '1%'
    }


    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhanced
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            uniqueFilter4={uniqueFilter4}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            theme={theme}
                            type={dynamicFilter}
                            filterParams={filterParams}
                            large={1}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    
                </View>
                    
                

            </View>
                <View style={panelChild}>
                    <View style={tablePanel}>
                        <AttEnhancedLookerTable
                            dataProp={aggView}
                            theme={theme}
                            models={models}
                            filterParams={filterParams}
                            dynamicFilter={dynamicFilter}
                            currentMetrics={currentMetrics}
                        />
                    

                    </View>
                    <View style={tablePanel}>
                        <AttEnhancedLookerTableDaily
                            dataProp={dailyView}
                            theme={theme}
                            models={models}
                            filterParams={filterParams}
                            dynamicFilter={dynamicFilter}
                            primaryMetric={primaryMetric}
                            currentMetrics={currentMetrics}
                        />

                    </View>
                   

                </View>
                
                
                <AttEnhancedLookerModels
                    open={attModalOpen}
                    setOpen={setOpen}
                    modelHandler={modelHandler}
                    models={models}
                    primaryMetric={primaryMetric}
                    primaryMetricHandler={primaryMetricHandler}
                />
            

        </View>

    )

}

export default AttEnhancedLookerContent

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93),
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize'
    }
})