import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import dashConfigOriginal from '../DashBuilder/dashConfig.json'
import moment from 'moment-timezone';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import DashPanel from '../DashBuilder/DashScreen/DashPanel';
import LoadingComponent from '../Components/Loader'
import NoDataContainer from '../Components/NoDataContainer.js';

const DashCustom = (props) => {

  const configArray = props.configArray
  const config_url = configArray.config_url
  const support_id = configArray.support_id
  const support_key = configArray.support_key
  const [dimResponse, setDimResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const currentMetrics = configArray.currentMetrics[0]
  const [dashConfigBase, setTheme] = useState(JSON.parse(localStorage.getItem('dashConfig')) ?? dashConfigOriginal)
  const tooltipArray = configArray.tooltipArray
  const dashConfig = dashConfigBase
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const client = clientID.toLowerCase();
  const cubeTable = client + "_daily"
  const theme = props.theme
  const homeTitle = "Dash Builder";
  const role = configArray.role
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const [modalVisible, setModalVisible] = useState(false)

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);


  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  const filterBase = {
    start: startDateString,
    end: yesterdayProp,
    channel: "Total",
    brand: 'Total',
    kw_type: "Total",
  };

  const [filters, setFilters] = useState(filterBase);

  var dataProp = []

  const propObjectBase = {
    data: dataProp,
    start: startDateString,
    end: yesterdayProp,
    theme: theme,
    brand: "Total",
    channel: "Total",
    kw_type: "Total",
  };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      channel: prop.channel,
      brand: prop.brand,
      kw_type: prop.kw_type,
    };

    setFilters(filterUpdate)

  };
    const cuebStart = filters.start
    const cubeEnd = filters.end

    const dimList = ['brand', 'channel', 'kw_type']


    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": cuebStart,
          "end": cubeEnd,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "impressions"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": []
        }
    }


    const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
        requestDimData(dimQuery, config_url, support_key, support_id, 'POST')
      }, [configArray, filters])

      if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }


    const dimCubeResponse = dimResponse.data

  const uniqueDimensions = ['date', 'brand', 'channel', 'kw_type']
  const uniqueMeasures = ["impressions","clicks","spend","regs","ftds","ngr"]

  if (dimCubeResponse.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
}

  const lastUpdated = 'loading...';

  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
  };


  const uniqueBrands = getUniqueValues(dimCubeResponse, 'brand');
  const uniqueChannels = getUniqueValues(dimCubeResponse, 'channel');
  const uniqueTypes = getUniqueValues(dimCubeResponse, 'kw_type');  

  

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        setModalVisible={setModalVisible}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
        setTheme={setTheme}
      />
      <DashPanel
        theme={theme}
        uniqueBrands={uniqueBrands}
        uniqueChannels={uniqueChannels}
        uniqueTypes={uniqueTypes}
        cubeTable={cubeTable}
        dashConfig={dashConfig}
        handleStateChange={handleStateChange}
        propObject={propObject}
        filters={filters}
        uniqueDimensions={uniqueDimensions}
        uniqueMeasures={uniqueMeasures}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        tooltipArray={tooltipArray}
        currentMetrics={currentMetrics}
        configArray={configArray}
        />

    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default DashCustom