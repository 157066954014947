// Routes.js
import HomeScreen from './Home/Home';
import Login from './Screens/Login';
import DailySummary from './DailySummary/Daily';
import MonthlySummary from './MonthlySummary/Monthly';
import WeeklySummary from './WeeklySummary/Weekly';
import CampaignSummary from './CampaignSummary/CampaignSummary';
import TableBuilder from './TableBuilder/Table';
import ChartBuilder from './ChartBuilder/ChartBuilder';
import DocBuilder from './DocBuilder/DocBuilder';
import Cohorts from './Screens/Cohorts';
import CohortArpu from './Cohorts/CohortsARPU/CohortArpu';
import CohortDeposits from './Cohorts/CohortsDeposits/CohortDeposits';
import Budgets from './Budgets/Budgets';
import Chat from './Screens/Chat';
import BudgetTable from './Budgets/BudgetTable';
import PPCCampaign from './PPC/PPCCampaign';
import PPCAdGroup from './PPC/PPCAdGroup';
import PPCKwTreeScreen from './PPC/PPCKwTreeScreen';
import SocialCampaign from './Paid Social/SocialCampaign';
import SocialAdGroup from './Paid Social/SocialAdGroup';
import AnalyticsMonthly from './Analytics/AnalyticsMonthly';
import AnalyticsDaily from './AnalyticsDaily/AnalyticsDaily';
import AnalyticsTable from './Analytics/AnalyticsTable';
import AnalyticsEntry from './AnalyticsEnhanced/AnalyticsEntry/AnalyticsEntry';
import AnalyticsContent from './AnalyticsEnhanced/AnalyticsEntry/AnalyticsContent';
import AnalyticsEnhancedPaths from './AnalyticsEnhanced/AnalyticsEnhancedPaths/AnalyticsEnhancedPaths';
import AnalyticsEnhancedPathsCampaign from './AnalyticsEnhanced/AnalyticsEnhancedPaths/AnalyticsEnhancedPathsCampaign';
import AnalyticsEvents from './AnalyticsEnhanced/AnalyticsEvents/AnalyticsEvents';
import AnalyticsNewReturning from './AnalyticsEnhanced/AnalyticsEntry/AnalyticsNewReturning';
import AnalyticsCWV from './AnalyticsEnhanced/AnalyticsCWV/AnalyticsCWV';
import AllChannels from './AllChannels/AllChannels';
import AttributionComparison from './Attribution/AttributionComparison';
import AttributionDimensions from './Attribution/AttributionDimensions';
import AttEnhanced from './AttributionEnhanced/AttEnhanced';
import AttEnhancedTable from './AttributionEnhanced/AttEnhancedTable/AttEnhancedTable';
import AttEnhancedKW from './AttributionEnhanced/AttEnhancedKW.js/AttEnhancedKW';
import AttEnhancedAdG from './AttributionEnhanced/AttEnhancedAdG.js/AttEnhancedAdG';
import AttEnhancedCamp from './AttributionEnhanced/AttEnhancedCamp/AttEnhancedCamp';
import AttEnhancedLooker from './AttributionEnhanced/AttEnhancedLooker/AttEnhancedLooker';
import ProgrammaticChannel from './Programmatic/ProgrammaticChannel';
import ProgrammaticPub from './Programmatic/ProgrammaticPub';
import ProgrammaticType from './Programmatic/ProgrammaticType';
import ProgrammaticExchange from './Programmatic/ProgrammaticExchange';
import ProgrammaticDevice from './Programmatic/ProgDevice';
import ProgrammaticBrowser from './Programmatic/ProgrammaticBrowser';
import ProgrammaticBanner from './Programmatic/ProgrammaticBanner';
import ProgrammaticBannerSize from './Programmatic/ProgrammaticBannerSize';
import DashCustom from './DashBuilder/DashCustom';
import Yearly from './Yearly/Yearly';
import Options from './Options/Options';
import SignedUp from './Screens/SignedUp';
import MobileScreen from './Mobile/MobileScreen';
import CanvasContainer from './ReactDnD/DndCanvas/CanvasContainer';
import DashEditor from './DashEditor/DashEditor';
import AllChannelDaily from './AllChannelSummary/AllChannelDaily';
import AllChannelWeekly from './AllChannelSummary/AllChannelWeekly/AllChannelWeekly';
import AllChannelMonthly from './AllChannelSummary/AllChannelMonthly/AllChannelMonthly';
import AllChannelYearly from './AllChannelSummary/AllChannelYearly/AllChannelYearly';
import ExecSummary from './ExecSummary/ExecSummary';
import ExecSummaryChannel from './ExecSummary/ExecSummaryChannel/ExecSummaryChannel';
import PivotTable from './Flexmonster/PivotTable';
import Cumulative from './Cumulative/Cumulative';
import ChartBuilderNew from './ChartBuilderNew/ChartBuilder';
import MovingAverage from './Statistics/MovingAverage/MovingAverage';
import ExpoMovingAverage from './Statistics/EMA/ExpoMovingAverage';
import MACD from './Statistics/MACD/MACD';
import CountrySummary from './CountrySummary/CountrySummary';
import CountrySummaryAll from './CountrySummary/CountrySummaryAll';
import Support from './Support/Support';
import SupportTickets from './Support/SupportTickets';
import AdGroupEnhanced from './AdGroupEnhanced/AdGroupEnhanced';
import AdGroupSocialEnhanced from './AdGroupEnhanced/AdGroupSocialEnhanced';
import CampaignEnhanced from './AdGroupEnhanced/CampaignEnhanced';
import CampaignSocialEnhanced from './AdGroupEnhanced/CampaignSocialEnhanced';
import Reactivations from './Reactivations/Reactivations';
import ReactivationsCampaign from './Reactivations/ReactivationsCampaign';
import ReactivationsAdGroup from './Reactivations/ReactivationsAdGroup';
import AnalyticsPathsRegStart from './AnalyticsEnhanced/AnalyticsEnhancedPathsRegStart/AnalyticsPathsRegStart';
import AnalyticsPathsRegStartCampaign from './AnalyticsEnhanced/AnalyticsEnhancedPathsRegStart/AnalyticsPathsRegStartCampaign';
import OptionsGrouping from './Options/OptionsGrouping/OptionsGrouping';


const AppRoutes = {
HomeScreen,
Login,
ExecSummary,
DailySummary,
MonthlySummary,
WeeklySummary,
CampaignSummary,
TableBuilder,
ChartBuilder,
ChartBuilderNew,
DocBuilder,
Cohorts,
CohortArpu,
CohortDeposits,
Budgets,
Chat,
BudgetTable,
PPCCampaign,
PPCAdGroup,
PPCKwTreeScreen,
SocialCampaign,
SocialAdGroup,
AnalyticsMonthly,
AnalyticsDaily,
AnalyticsTable,
AnalyticsEntry,
AnalyticsContent,
AnalyticsEnhancedPaths,
AnalyticsEnhancedPathsCampaign,
AnalyticsEvents,
AnalyticsCWV,
AnalyticsNewReturning,
AnalyticsPathsRegStart,
AllChannels,
AttributionComparison,
AttributionDimensions,
AttEnhanced,
AttEnhancedTable,
AttEnhancedKW,
AttEnhancedAdG,
AttEnhancedCamp,
AttEnhancedLooker,
ProgrammaticChannel,
ProgrammaticPub,
ProgrammaticType,
ProgrammaticExchange,
ProgrammaticDevice,
ProgrammaticBrowser,
ProgrammaticBanner,
ProgrammaticBannerSize,
DashCustom,
Yearly,
Options,
SignedUp,
MobileScreen,
CanvasContainer,
DashEditor,
AllChannelDaily,
AllChannelWeekly,
AllChannelMonthly,
AllChannelYearly,
PivotTable,
Cumulative,
MovingAverage,
ExpoMovingAverage,
MACD,
CountrySummary,
CountrySummaryAll,
Support,
SupportTickets,
AdGroupEnhanced,
AdGroupSocialEnhanced,
CampaignEnhanced,
CampaignSocialEnhanced,
Reactivations,
ReactivationsCampaign,
ReactivationsAdGroup,
AnalyticsPathsRegStartCampaign,
ExecSummaryChannel,
OptionsGrouping
}

export default AppRoutes;
