import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native'

import OptionsGroupingAddModal from './OptionsGroupingAddModal';
import OptionsGroupingDeleteModal from './OptionsGroupingDeleteModal';
import OptionsGroupingLoader from './OptionsGroupingLoader';
import OptionsGroupingPreview from '../OptionsGroupingPreview/OptionsGroupingPreview';
import OptionsGroupingPreviewCubeCase from '../OptionsGroupingPreview/OptionsGroupingPreviewCubeCase';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { postToSignedUrl } from './OptionsGroupingGET';
import signRequest from './OptionsGroupingGET';


const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;

const OptionsGroupingEditDefinitions = (props) => {
    const theme = props.theme
    const groupingData = props.groupingData
    const setCurrentConfig = props.setCurrentConfig
    const edit = props.edit
    const setEdit = props.setEdit
    const setGroupingData = props.setGroupingData
    const saveArray = props.saveArray
    const cubeTable = props.cubeTable
    const currentMetrics = props.currentMetrics
    const updateArray = props.updateArray
    const [dimension, setDimension] = useState(null)
    const [operator, setOperator] = useState(null)
    const [value, setValue] = useState(null)
    const [returnValue, setReturnValue] = useState(null)
    const [addOpen, setAddOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteCondition, setDeleteCondition] = useState(false)
    const [preSave, setPreSave] = useState(groupingData)
    const [preview, setPreview] = useState(false)
    const saveStateArray = props.saveStateArray
    const saveLoading = saveStateArray.saveLoading
    const setSaveLoading = saveStateArray.setSaveLoading
    const setSaveResponse = saveStateArray.setSaveResponse

    const dimensionOptions = ['brand', 'channel', 'campaign', 'adgroup']
    const operatorOptions = ['contains', 'equals']

    const [currentGroup, setCurrentgroup] = useState(null)

    if (!groupingData) {
        return null
    }
    const custom_groupings = groupingData.data.custom_groupings
    const custom_dimensions = custom_groupings.custom_dimensions
    const custom_metrics = custom_groupings.custom_metrics


    const groupingMap = [
        {
            id: "custom_dimensions",
            title: "Custom Dimensions",
            dataProp: custom_dimensions,
            isAllPercent: false
        }
        //,
        //{
            //id: "custom_metrics",
            //title: "Custom Metrics",
            //dataProp: custom_metrics,
            //isAllPercent: false
        //}
    ]

    const ConditionMap = (props) => {
        if (!deleteCondition) {
            return <ChildItem
                child={props.child}
                index={props.index}
                parentId={props.parentId}
                parentItem={props.parentItem}
                elseValue={props.elseValue}
                originalProp={props.originalProp}

            />
        } else return <ChildItemDelete
            child={props.child}
            index={props.index}
            parentId={props.parentId}
            parentItem={props.parentItem}
            Dropdown={Dropdown}
            TextBox={TextBox}
            elseValue={props.elseValue}
            originalProp={props.originalProp}
        />
    }

    const sendDataToLambda = async (event_data, support_url, support_key, support_id, method) => {
        setSaveLoading(true); // Start loading
        setSaveResponse([]); // Clear previous response

        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);

            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setSaveResponse(result)
            //setSaveLoading(false);
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            setSaveLoading(false);
        }
    };

    const previewHandler = () => {
        setPreview(true)
    }

    const savehandler = (props) => {

        const newModel = OptionsGroupingPreviewCubeCase(preSave, cubeTable, saveArray.client, saveArray.auth0_user_id)
        const customModel = {
            "custom_model" : newModel
        }
        Object.assign(preSave.data, customModel)

        const event_data = {
            client: saveArray.client,
            client_id: saveArray.clientMapId,
            auth0_user_id: saveArray.auth0_user_id,
            "request_type": "custom_configs_update",
            "configData": preSave.data,
        }

        
        sendDataToLambda(event_data, saveArray.support_url, saveArray.support_key, saveArray.support_id, 'POST')
        setGroupingData(preSave)
        setEdit(false)

    }

    const fieldUpdate = (updateProps, idx, saveitems) => {
        const currentGrouping = updateProps.parentItem.id
        const parentId = updateProps.parentId
        const baseCopy = JSON.parse(JSON.stringify(groupingData));
        const currentItem = baseCopy.data.custom_groupings[currentGrouping]
        const currentIndex = currentItem.findIndex(item => item.hasOwnProperty(parentId));
        const originalItem = baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][idx]
        const originalReturn = originalItem.returnValue
        const originalDimension = originalItem.dimension
        const originalValue = originalItem.value
        const originalOperator = originalItem.operator

        const originalItemElse = baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][0].else

        const valuehandler = (value, original, operator) => {

            if (value === null && original === undefined) {
                return baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][idx][operator];
            } else if (!value) {
                return original
            } else if (value === "") {
                return original
            } else {
                return value
            }
        }

        const newItem = {
            return_value: valuehandler(saveitems.returnValue, originalReturn, "return_value"),
            dimension: valuehandler(saveitems.dimension, originalDimension, "dimension"),
            value: valuehandler(saveitems.value, originalValue, "value"),
            operator: valuehandler(saveitems.operator, originalOperator, "operator"),
            else: valuehandler(saveitems.else, originalItemElse, "else")
        }

        baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][idx] = newItem

        setCurrentConfig(baseCopy)
        setPreSave(baseCopy)


    }

    const elseUpdate = (updateProps, idx, value, type) => {
        const currentGrouping = updateProps.parentItem.id
        const parentId = updateProps.parentId
        const baseCopy = JSON.parse(JSON.stringify(groupingData));
        const currentItem = baseCopy.data.custom_groupings[currentGrouping]
        const currentIndex = currentItem.findIndex(item => item.hasOwnProperty(parentId));
        const originalItem = baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][0]
        const originalElse = baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][0].else

        const valuehandler = (value, original) => {

            if (value === null && original === undefined) {
                return baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][0]['else'];
            } else if (!value) {
                return original
            } else if (value === "") {
                return original
            } else {
                return value
            }
        }

        const elseValue = valuehandler(value, originalElse)

        baseCopy.data.custom_groupings[currentGrouping][currentIndex][parentId][0].else = elseValue
        setCurrentConfig(baseCopy)
        setPreSave(baseCopy)

    }

    const addItem = (child, newDim) => {
        const baseCopy = JSON.parse(JSON.stringify(groupingData));
        const currentGrouping = currentGroup.id
        const newItem = {
            [newDim]: [{
                "return_value": "",
                "dimension": 'brand',
                "value": "",
                "operator": "equals"
            }]
        }
        const currentItem = baseCopy.data.custom_groupings[currentGrouping] || [];
        const newList = [...currentItem, newItem]
        baseCopy.data.custom_groupings[currentGrouping] = newList
        setCurrentConfig(baseCopy)
        setPreSave(baseCopy)

    }

    const addClickHandler = (child) => {
        setCurrentgroup(child)
        setAddOpen(true)
    }

    const deleteClickHandler = (child) => {
        setCurrentgroup(child)
        setDeleteOpen(true)
    }

    const deleteConditionClickHandler = (props) => {
        setDeleteCondition(true)
    }

    const deleteConditionHandler = (index, parentId, child) => {

        const baseCopy = JSON.parse(JSON.stringify(groupingData));
        const currentGrouping = child.id;
        const currentItem = baseCopy.data.custom_groupings[currentGrouping] || [];
        const foundItem = currentItem.find(item => item.hasOwnProperty(parentId));

        if (foundItem && Array.isArray(foundItem[parentId])) {
            // Remove the item at the specified index
            foundItem[parentId].splice(index, 1);
        } else {
            console.log("Item not found or item structure is invalid.");
        }

        const currentIndex = currentItem.findIndex(item => item.hasOwnProperty(parentId));

        if (index === 0) {
            return
        } else {
            baseCopy.data.custom_groupings[currentGrouping][currentIndex] = foundItem
            setCurrentConfig(baseCopy);
            setPreSave(baseCopy);
        }



    }



    const deleteItem = (removeDim) => {
        const baseCopy = JSON.parse(JSON.stringify(groupingData));
        const currentGrouping = currentGroup.id;
        const currentItem = baseCopy.data.custom_groupings[currentGrouping] || [];
        const newList = currentItem.filter(item => !item.hasOwnProperty(removeDim));
        baseCopy.data.custom_groupings[currentGrouping] = newList;
        setCurrentConfig(baseCopy);
        setPreSave(baseCopy);
    }

    const addCondition = (newCondition, parentid, child) => {
        const baseCopy = JSON.parse(JSON.stringify(groupingData));
        const currentGrouping = child.id;
        const currentItem = baseCopy.data.custom_groupings[currentGrouping] || [];
        const foundItem = currentItem.find(item => item.hasOwnProperty(parentid));

        // Access the value of the key if found
        const values = foundItem ? foundItem[parentid] : undefined;

        const newItem = {
            "return_value": "",
            "dimension": 'brand',
            "value": "",
            "operator": "equals"
        }

        const newList = [...values, newItem]

        const index = currentItem.findIndex(item => item.hasOwnProperty(parentid));

        baseCopy.data.custom_groupings[currentGrouping][index][parentid] = newList
        setCurrentConfig(baseCopy);
        setPreSave(baseCopy);

    }



    const dimensionsMap = dimensionOptions.map((item, index) => (
        <MenuItem key={`dimension-${index}`} value={item}>
            {item}
        </MenuItem>
    ));

    const operatorMap = operatorOptions.map((item, index) => (
        <MenuItem key={`operator-${index}`} value={item}>
            {item}
        </MenuItem>
    ));

    const Dropdown = (props) => {

        const value = props.currentValue
        const options = props.options
        const disabled = props.disabled
        const setValue = props.setValue
        const updateProps = props.updateProps
        const idx = props.idx
        const type = props.type
        const [textValue, setTextValue] = useState(value)

        const updateHander = (value) => {

            //setValue(updateProps, idx, value, type)
            setValue(value)
            setTextValue(value)

        }

        return (
            <Box component="div" sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',

            }}>
                <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>

                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={textValue}
                        disabled={disabled}
                        sx={{
                            fontFamily: 'Electrolize',
                            width: '100%'
                        }}


                        onChange={(event) => updateHander(event.target.value)}
                        input={<OutlinedInput />}
                    >
                        {options}
                    </Select>
                </FormControl>

            </Box>
        )
    }

    const ElseDropdown = (props) => {

        const value = props.currentValue
        const options = ['brand', 'channel', 'campaign', 'adgroup']
        const disabled = props.disabled
        const setValue = props.setValue
        const updateProps = props.updateProps
        const idx = props.idx
        const type = props.type
        const [textValue, setTextValue] = useState(value)

        const elseMap = options.map((item, index) => (
            <MenuItem key={`operator-${index}`} value={item}>
                {item}
            </MenuItem>
        ));

        const updateHander = (value) => {

            setValue(updateProps, idx, value, type)
            setTextValue(value)

        }

        return (
            <Box component="div" sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',

            }}>
                <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>

                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={textValue}
                        disabled={disabled}
                        sx={{
                            fontFamily: 'Electrolize',
                            width: '100%'
                        }}


                        onChange={(event) => updateHander(event.target.value)}
                        input={<OutlinedInput />}
                    >
                        {elseMap}
                    </Select>
                </FormControl>

            </Box>
        )
    }

    const TextBox = (props) => {
        const value = props.currentValue
        const disabled = props.disabled
        const setValue = props.setValue
        const updateProps = props.updateProps
        const idx = props.idx
        const type = props.type
        const [textValue, setTextValue] = useState(value)

        const updateHander = (value) => {

            if (value === null || value === undefined || value === "") return;

            setValue(value);
            setTextValue(value);

        }

        return (
            <Box component="div" sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
            }}>
                <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>

                    <OutlinedInput
                        id="outlined-search"
                        onChange={e => updateHander(e.target.value)}
                        type="search"
                        value={textValue}
                        disabled={disabled}
                        sx={{
                            width: '100%',
                            left: 0,
                            fontFamily: 'Electrolize',
                            color: theme.color,
                            backgroundColor: theme.backgroundColor,
                            textAlign: 'center'
                        }}

                    />
                </FormControl>

            </Box>
        )
    }

    const GroupContainer = ({ child, index }) => {

        return (
            <View style={styles.groupContainer}>
                <OptionsGroupingAddModal
                    open={addOpen}
                    setOpen={setAddOpen}
                    theme={theme}
                    addItem={addItem}
                    child={child}
                    childIndex={index}
                    key={`group-container-${index}`}
                />
                <OptionsGroupingDeleteModal
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    theme={theme}
                    deleteItem={deleteItem}
                    child={child}
                    childIndex={index}
                    key={`delete-modal-${index}`}
                    currentGroup={currentGroup}
                />
                <View style={styles.groupHeader}>
                    <View style={{ width: '70%' }}>
                        <Text style={{ fontFamily: 'Electrolize', color: theme.color, fontSize: windowWidth * 0.008, fontWeight: 600 }}>
                            {child.id}
                        </Text>
                    </View>
                    <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                        <ColorButtonPrimary variant="contained" onClick={() => addClickHandler(child)} theme={theme}>ADD ITEM</ColorButtonPrimary>
                    </View>
                    <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                        <ColorButtonPrimary variant="contained" onClick={() => deleteClickHandler(child)} theme={theme}>DELETE ITEM</ColorButtonPrimary>
                    </View>
                </View>
                {child.dataProp.map((childItem, i) => {
                    const parent_id = (Object.keys(childItem))
                    return <ChildContainer dataProp={Object.values(childItem)} id={Object.keys(childItem)} child_name={parent_id} key={`child-container-${index}-${i}`} child={child} />
                }
                )}
            </View>
        )
    }


    const ChildContainer = (props) => {

        const elseValue = props.dataProp[0][0].else

        return (
            <View style={styles.childContainer} key={`child-container-${props.id}`}>
                <View style={styles.childContainerHeader}>
                    <Text>{props.id}</Text>
                </View>
                {props.dataProp.map((child, idx) => {

                    return <ConditionMap child={child} index={idx} parentId={props.child_name} key={`child-item-${props.id}-${idx}`} parentItem={props.child} elseValue={elseValue} originalProp={props.dataProp} />
                }
                )}
            </View>
        )
    }

    const ElseContainer = ({ elseValue, index, updateProps, saveItems, setValue }) => {

        const elseOutput = elseValue

        const childItemStyle = {
            width: '25%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 0,
            alignItems: 'center',
            justifyContent: 'center'
        };

        return <View style={styles.childElse} key={`else-${index}-${index}`}>
            <View style={childItemStyle}>
                <TextBox
                    setValue={setReturnValue}
                    currentValue={'Else'}
                />
            </View>
            <View style={childItemStyle}>
                <ElseDropdown
                    setValue={setValue}
                    currentValue={elseOutput}
                    options={operatorMap}
                    updateProps={updateProps}
                    saveItems={saveItems}
                    index={index}
                />
            </View>
        </View>
    }

    const ChildItem = ({ child, index, parentId, parentItem, elseValue, originalProp }) => {

        const updateProps = { child, index, parentId, parentItem, elseValue, originalProp }
        const [dimension, setDimension] = useState(null)
        const [operator, setOperator] = useState(null)
        const [value, setValue] = useState(null)
        const [returnValue, setReturnValue] = useState(null)

        const saveitems = {
            dimension,
            operator,
            value,
            returnValue
        }

        const childItemStyle = {
            width: '23%',
            height: '100%',
            borderColor: 'red',
            borderWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
        }

        const childDeleteStyle = {
            width: '8%',
            height: '95%',
            borderColor: 'red',
            borderWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'rgba(137, 148, 153, 0.6)',
            borderWidth: 1,
            borderRadius: 5
        }

        const childHeaderStyle = {
            width: '23%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundColor2
        }

        const headerTextStyle = {
            fontFamily: 'Electrolize',
            color: 'white',
            fontWeight: 'bold',
            fontSize: windowWidth * 0.011
        }

        const headerDeleteStyle = {
            width: '8%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundColor2
        }

        const deleteIconStyle = {
            fontFamily: 'Electrolize',
            color: theme.backgroundColor2,
            fontWeight: 'bold',
            fontSize: windowWidth * 0.015,
        }

        return (
            <View style={{ flex: 1 }} key={`child-item-container-${index}`}>
                <View style={styles.child}>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>DIMENSION</Text>
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>OPERATOR</Text>
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>VALUE</Text>
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>RETURN VALUE</Text>
                    </View>
                    <View style={headerDeleteStyle}>
                        <Text style={headerTextStyle}>SAVE</Text>
                    </View>
                </View>
                {child.map((item, idx) => (
                    <View style={styles.child} key={`nested-child-item-${index}-${idx}`}>
                        <View style={childItemStyle}>
                            <Dropdown setValue={setDimension} currentValue={item.dimension} options={dimensionsMap} disabled={false} updateProps={updateProps} idx={idx} type={'dimension'} />
                        </View>
                        <View style={childItemStyle}>
                            <Dropdown setValue={setOperator} currentValue={item.operator} options={operatorMap} disabled={false} updateProps={updateProps} idx={idx} type={'operator'} />
                        </View>
                        <View style={childItemStyle}>
                            <TextBox setValue={setValue} currentValue={item.value} disabled={false} updateProps={updateProps} idx={idx} type={'value'} />
                        </View>
                        <View style={childItemStyle}>
                            <TextBox setValue={setReturnValue} currentValue={item.return_value} disabled={false} updateProps={updateProps} idx={idx} type={'return_value'} />
                        </View>
                        <Pressable style={childDeleteStyle}
                            onPress={() => fieldUpdate(updateProps, idx, saveitems)}
                        >
                            <SaveIcon style={deleteIconStyle} />
                        </Pressable>
                    </View>
                ))}
                <ElseContainer
                    elseValue={elseValue}
                    index={index}
                    updateProps={updateProps}
                    saveItems={saveitems}
                    setValue={elseUpdate}
                />
                {edit && (
                    <View style={{ flexDirection: 'row', justifyContent: 'end', padding: '1%' }}>
                        <View style={{ width: '15%', alignItems: 'end' }}>
                            <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => addCondition(child, parentId, parentItem)} >
                                <ColorButtonPrimary variant="contained" theme={theme}>
                                    ADD CONDITION
                                </ColorButtonPrimary>
                            </View>
                        </View>
                        <View style={{ width: '15%', alignItems: 'end' }}>
                            <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => deleteConditionClickHandler(child, parentId, parentItem)} >
                                <ColorButtonPrimary variant="contained" theme={theme}>
                                    DELETE CONDITION
                                </ColorButtonPrimary>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        )
    }

    const ChildItemDelete = ({ child, index, parentId, parentItem }) => {

        const childItemStyle = {
            width: '23%',
            height: '100%',
            borderColor: 'red',
            borderWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
        }

        const childDeleteStyle = {
            width: '8%',
            height: '95%',
            borderColor: 'red',
            borderWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'rgba(137, 148, 153, 0.6)',
            borderWidth: 1,
            borderRadius: 5
        }

        const childHeaderStyle = {
            width: '23%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundColor2
        }

        const headerTextStyle = {
            fontFamily: 'Electrolize',
            color: 'white',
            fontWeight: 'bold',
            fontSize: windowWidth * 0.011
        }

        const headerDeleteStyle = {
            width: '8%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundColor2
        }

        const deleteIconStyle = {
            fontFamily: 'Electrolize',
            color: 'grey',
            fontWeight: 'bold',
            fontSize: windowWidth * 0.015,
        }

        const altIconStyle = {
            fontFamily: 'Electrolize',
            color: 'red',
            fontWeight: 'bold',
            fontSize: windowWidth * 0.015,
        }

        const deleteState = (index) => {
              if (index === 0) {
                return altIconStyle
            } else {
                return deleteIconStyle
            }
        }



        return (
            <View style={{ flex: 1 }} key={`child-item-container-${index}`}>
                <View style={styles.child}>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>DIMENSION</Text>
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>OPERATOR</Text>
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>VALUE</Text>
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>RETURN VALUE</Text>
                    </View>
                    <View style={headerDeleteStyle}>
                        <Text style={headerTextStyle}>DELETE</Text>
                    </View>
                </View>
                {child.map((item, idx, index) => (
                    <View style={styles.child} key={`nested-child-item-${index}-${idx}`}>
                        <View style={childItemStyle}>
                            <Dropdown setValue={setDimension} currentValue={item.dimension} options={dimensionsMap} disabled={true} />
                        </View>
                        <View style={childItemStyle}>
                            <Dropdown setValue={setOperator} currentValue={item.operator} options={operatorMap} disabled={true} />
                        </View>
                        <View style={childItemStyle}>
                            <TextBox setValue={setValue} currentValue={item.value} disabled={true} />
                        </View>
                        <View style={childItemStyle}>
                            <TextBox setValue={setReturnValue} currentValue={item.return_value} disabled={true} />
                        </View>
                        <Pressable style={childDeleteStyle} onPress={() => deleteConditionHandler(idx, parentId, parentItem)}>
                            <RemoveCircleOutlineIcon style={deleteState(idx)} />
                        </Pressable>
                    </View>
                ))}
                {edit && (
                    <View style={{ flexDirection: 'row', justifyContent: 'end', padding: '1%' }}>
                        <View style={{ width: '15%', alignItems: 'end' }}>
                            <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => addCondition(child, parentId, parentItem)} >
                                <ColorButtonPrimary variant="contained" theme={theme}>
                                    ADD CONDITION
                                </ColorButtonPrimary>
                            </View>
                        </View>
                        <View style={{ width: '15%', alignItems: 'end' }}>
                            <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => setDeleteCondition(false)} >
                                <ColorButtonPrimary variant="contained" theme={theme}>
                                    STOP DELETING
                                </ColorButtonPrimary>
                            </View>
                        </View>
                    </View>
                )}
            </View>
        )
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor,
        },
    }));

    const ColorButtonPrimary = styled(Button)(({ theme }) => ({
        color: 'white',
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    const parentView = () => {

        if (!preview) {
            return (
                <View style={{ width: '100%' }}>
                    {groupingMap.map((child, index) =>
                        <View style={{ width: '100%' }} key={`grouping-map-${index}`}>
                            <GroupContainer child={child} index={index} />
                        </View>
                    )}
                </View>
            )
        } else {
            return <OptionsGroupingPreview
                theme={theme}
                groupingData={groupingData}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}
                updateArray={updateArray}
            />
        }

    }

    const PreviewButton = () => {
        if (!preview) {
            return <ColorButton variant="contained"
                theme={theme}
                endIcon={<PreviewIcon />}
                onClick={() => previewHandler()}
            >
                PREVIEW
            </ColorButton>
        } else {
            return <ColorButton variant="contained"
                theme={theme}
                endIcon={<PreviewIcon />}
                onClick={() => setPreview(false)}
            >
                CLOSE PREVIEW
            </ColorButton>
        }
    }


    return (
        <View style={styles.container}>
            <View style={{ width: '100%', height: '100%', borderColor: 'grey', borderWidth: 1, backgroundColor: theme.backgroundColor2, alignItems: 'center', justifyContent: 'end', padding: '0.5%', flexDirection: 'row' }}>
                <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                    {PreviewButton()}
                </View>
                <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => savehandler()}
                        endIcon={<SendIcon />}>
                        SAVE ALL
                    </ColorButton>
                </View>
                <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => setEdit(false)}
                        endIcon={<ExitToAppIcon />}
                    >
                        CLOSE WITHOUT SAVING
                    </ColorButton>
                </View>
            </View>
            {parentView()}
            <OptionsGroupingLoader
                theme={theme}
                open={saveLoading}
                message={'Saving Configs...'}
            />
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: windowWidth * 0.9,
        //height: windowheight * 0.93,
        height: '100%',
        padding: '1%',
        borderWidth: 0,
        borderColor: 'yellow',
        //overflow: 'auto'

    },
    groupContainer: {
        width: '100%',
        //minHeight: windowWidth * 0.2,
        //minHeight: '45%',
        height: '75%',
        borderWidth: 2,
        borderColor: 'grey',
        padding: '1%',
        marginTop: '1%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    groupHeader: {
        height: '5%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
        alignItems: 'center',
        marginBottom: '1%',
        flexDirection: 'row'
    },
    childContainer: {
        width: '100%',
        borderWidth: 3,
        borderColor: 'grey',
        //minHeight: '100%',
        flexDirection: 'column',
        padding: '0.5%',
        marginBottom: '1%'

    },
    childContainerHeader: {
        width: '100%',
        height: '10%',

    },
    child: {
        width: '100%',
        height: 75,
        borderWidth: 0,
        borderColor: 'red',
        flexDirection: 'row',
        padding: '0.5%',
        alignItems: 'center'
    },
    childElse: {
        width: '100%',
        height: 75,
        borderWidth: 0,
        borderColor: 'red',
        flexDirection: 'row',
        padding: '0.5%',
        justifyContent: 'right'
    }
})

export default OptionsGroupingEditDefinitions