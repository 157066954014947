import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import LoadingComponent from '../Components/Loader.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import ChartBuilderPanels from './ChartBuilderPanels.js';
import NoData from '../Components/NoData.js';
import ParseDaily from '../DailySummary/CubeFunctions/ParseDaily.js';
import ChartBuilderCampaignCube from './ChartBuilderCampaign/ChartBuilderCampaignCube.js';
import CubeBudget from '../Budgets/CubeFunctions/CubeBudget.js';
import NoDataContainer from '../Components/NoDataContainer.js';
import PivotDims from '../Flexmonster/CubeFunctions/PivotDims.js';

const ChartBuilderPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [adgroupResponse, setAdgroupResponse] = useState(null)
    const [budgetResponse, setBudgetResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentReportConfig = props.currentReportConfig
    const setCurrentReportConfig = props.setCurrentReportConfig
    const flexConfigStorage = props.flexConfigStorage
    const lastFlexUsed = props.lastFlexUsed
    const newSettings = props.newSettings
    const setNewSettings = props.setNewSettings
    const reportList = props.reportList
    const reportMap = props.reportMap
    const [panelContent, setPanelContent] = useState('time')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
     const dynamicFilter = 'channel'

    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    let dynamicFilter3;
    

    if (panelContent === 'adgroup') {
        dynamicFilter3 ='kw_type'
    } else if (panelContent === 'budget') {
        dynamicFilter3 = 'kw_type'
    } else if (panelContent === 'time') {
        dynamicFilter3 = 'kw_type'
    }

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = dynamicFilter3
    const filter4name = 'country'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')
    const [filter4List, setFilter4List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : [],
        filter4List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);


    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter4name,
        filter1List,
        filter2List,
        filter3List,
        filter4List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
        setFilter4List
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }



    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
        };

        setFilters(filterUpdate)

    };

    const dimList = [filter1name, filter2name, filter3name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const filterSql = [dim1Value, dim2Value, dim3Value]

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": end,
          "table": `${client}_daily`,
          "date_dim": "date",
          "dimensions": ['date'],
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const budgetQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": end,
          "table": `${client}_budget`,
          "date_dim": "calendar_date",
          "dimensions": ['brand', 'channel', 'kw_type', 'calendar_date', 'current_dom', 'days_in_month', 'days_remaining'],
          "measures": [
            "actual_spend",
            "yesterday_spend",
            "budget",
            "reg",
            "ftd",
            "ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const campaignQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": end,
          "table": `${client}_adgroup`,
          "date_dim": "date",
          "dimensions": ['brand', 'channel', 'campaign', 'adgroup'],
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ftd_ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": end,
          "table": `${client}_daily`,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "impressions"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestAdgroupData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setAdgroupResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setAdgroupResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestBudgetData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setBudgetResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setBudgetResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
            requestData(cubeQuery, config_url, support_key, support_id, 'POST');
            requestAdgroupData(campaignQuery, config_url, support_key, support_id, 'POST');
            requestBudgetData(budgetQuery, config_url, support_key, support_id, 'POST');
            requestDimData(dimQuery, config_url, support_key, support_id, 'POST');

    }, [configArray, filters]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      if (!budgetResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      if (!adgroupResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponse = configResponse.data
    const cubeAdgroupResponse = adgroupResponse.data
    const cubeBudgetResponse = budgetResponse.data
    const dimCubeResponse = dimResponse.data

    let dynamicData;

    if (panelContent === 'adgroup') {
        dynamicData = cubeAdgroupResponse
    } else if (panelContent === 'time') {
        dynamicData = cubeResponse
    } else if (panelContent === 'budget') {
        dynamicData = cubeBudgetResponse
    }
    

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    

    const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)
    const uniqueFilter2 = getUniqueValues(dimCubeResponse, filter2name)
    const uniqueFilter3 = getUniqueValues(dimCubeResponse, filter3name)
    const uniqueFilter4 = getUniqueValues(dimCubeResponse, filter4name)

    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }
    
    const dataProp = {
        aggResult: dynamicData,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
        uniqueFilter4,
    }

    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <ChartBuilderPanels
                        theme={theme}
                        dataProp={dataProp}
                        dynamicFilter={dynamicFilter}
                        attModalOpen={attModalOpen}
                        setOpen={setOpen}
                        filterParams={filterParams}
                        propObject={propObject}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                        panelContent={panelContent}
                        setPanelContent={setPanelContent}
                        currentReportConfig={currentReportConfig}
                        setCurrentReportConfig={setCurrentReportConfig}
                        flexConfigStorage={flexConfigStorage}
                        lastFlexUsed={lastFlexUsed}
                        newSettings={newSettings}
                        setNewSettings={setNewSettings}
                        reportList={reportList}
                        reportMap={reportMap}
                        handleStateChange={handleStateChange}
                    />

            </View>
        </View>

    )

}

export default ChartBuilderPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})