import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import LoadingComponent from '../Components/Loader.js';
import PivotTableContent from './PivotTableContent.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const PivotTablePanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentReportConfig = props.currentReportConfig
    const setCurrentReportConfig = props.setCurrentReportConfig
    const flexConfigStorage = props.flexConfigStorage
    const lastFlexUsed = props.lastFlexUsed
    const newSettings = props.newSettings
    const setNewSettings = props.setNewSettings
    const reportList = props.reportList
    const reportMap = props.reportMap
    const fmKey = props.fmKey
    const [panelContent, setPanelContent] = useState('summary')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
    const cubeTable = `${client}_adgroup`
    const dynamicFilter = 'channel'

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'campaign'

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 30);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);

    const cubeDims = ['date', filter1name, filter2name, filter3name, 'adgroup']


    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": yesterdayProp,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ftd_ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": []
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
      }, [configArray])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }


    const cubeResponse = configResponse.data


    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const dataProp = {
        aggResult: cubeResponse,

    }

    const pivotConfig = {
        data: cubeResponse,
        dataset: panelContent,
        fields: Object.keys(cubeResponse[0]),
        dim1: filter1name,
        dim2: filter2name,
        dim3: filter3name
    }


    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <PivotTableContent
                    theme={theme}
                    dataProp={dataProp}
                    dynamicFilter={dynamicFilter}
                    attModalOpen={attModalOpen}
                    setOpen={setOpen}
                    currentMetrics={currentMetrics}
                    panelContent={panelContent}
                    setPanelContent={setPanelContent}
                    pivotConfig={pivotConfig}
                    currentReportConfig={currentReportConfig}
                    setCurrentReportConfig={setCurrentReportConfig}
                    flexConfigStorage={flexConfigStorage}
                    lastFlexUsed={lastFlexUsed}
                    newSettings={newSettings}
                    setNewSettings={setNewSettings}
                    reportList={reportList}
                    reportMap={reportMap}
                    clientID={clientID}
                    fmKey={fmKey}
                />

            </View>
        </View>

    )

}

export default PivotTablePanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})