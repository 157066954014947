import moment from 'moment-timezone';

export default function ParseLookerDaily (aggResult, dimension, metric, model, start, endDate) {

    var dataset = aggResult
    var dataset2 = aggResult
    var dataset3 = aggResult

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    var agg_result = [];


    agg_result = dataset.map((obj) => {
        //const impressions = obj.impressions
        const date = obj.date;
        const ftdsBase = `ftds_${model}`
        const reactBase = `react_${model}`
        const ngrBase = `ngr_${model}`
        const objDimension = dimension
        const objValue = obj[dimension]
        const clicks = obj.clicks || 0
        const spend = obj.spend || 0;
        //const regs = obj.regs
        //const ftds = obj.ftds;
        const ngr = obj[ngrBase] || 0
        //const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        //const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        //const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        const totalConv = obj[reactBase] + obj[ftdsBase] || 0
        const netProfit = ngr - spend || 0
        const recovered = (ngr / spend) || 0
        const costConv = spend === 0 || totalConv === 0 || isNaN(totalConv) ? 0 : spend / totalConv;

        return {
            
            [objDimension]: objValue,
            date: date,
            ngr: ngr,
            clicks: clicks,
            totalConv: totalConv,
            costConv: parseFloat((costConv).toFixed(2)),
            cpc: parseFloat((cpc).toFixed(2)),
            spend: parseFloat((spend)),
            netProfit: parseFloat((netProfit).toFixed(2)),
            recovered: parseFloat((recovered).toFixed(4)),
        };
    });

    var aggregatedResult = []

    dataset2.reduce(function (res, value) {

        const regs = 'regs_ft_lifetime'
        const ftds = `ftds_${model}`
        const react = `react_${model}`
        const ngr = `ngr_${model}`
        const impressions = 'impressions'
        const clicks = 'clicks'
        const spend = 'spend'
        const xAxis = value[dimension]

        if (!res[xAxis]) {
            res[xAxis] = {
                [dimension]: xAxis,
                impressions: 0,
                clicks: 0,
                spend: 0,
                regs: 0,
                ftds: 0,
                react: 0,
                ngr: 0
            };
            aggregatedResult.push(res[xAxis]);
        }
        res[xAxis].impressions += parseInt(value[impressions]) || 0;
        res[xAxis].clicks += parseInt(value[clicks]) || 0;
        res[xAxis].spend += parseInt(value[spend]) || 0;
        res[xAxis].regs += parseInt(value[regs]) || 0;
        res[xAxis].ftds += parseInt(value[ftds]) || 0;
        res[xAxis].react += parseInt(value[react]) || 0;
        res[xAxis].ngr += parseInt(value[ngr]) || 0;

        return res;
    }, {});


    aggregatedResult = aggregatedResult.map((obj) => {
        //const impressions = obj.impressions
        const date = moment.tz(obj.date, 'Europe/Paris').format('YYYY-MM-DD')
        const ftdsBase = obj.ftds
        const reactBase = obj.react
        const ngrBase = obj.ngr
        const objDimension = dimension
        const objValue = obj[dimension]
        const clicks = obj.clicks || 0
        const spend = obj.spend || 0;
        //const regs = obj.regs
        //const ftds = obj.ftds;
        const ngr = ngrBase || 0
        //const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftdsBase === 0 || isNaN(ftdsBase) ? 0 : spend / ftdsBase;
        //const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        const totalConv = reactBase + ftdsBase || 0
        const netProfit = ngr - spend || 0
        const recovered = (ngr / spend) || 0
        const costConv = spend === 0 || totalConv === 0 || isNaN(totalConv) ? 0 : spend / totalConv;

        return {
            
            [objDimension]: objValue,
            date: start,
            ngr: parseFloat((ngr).toFixed(2)),
            clicks: clicks,
            totalConv: totalConv,
            cpa: parseFloat((cpa).toFixed(2)),
            costConv: parseFloat((costConv).toFixed(2)),
            cpc: parseFloat((cpc).toFixed(2)),
            spend: parseFloat((spend)),
            netProfit: parseFloat((netProfit).toFixed(2)),
            recovered: parseFloat((recovered).toFixed(4)),
        };
    });

    function filterByDate(objects, targetDate) {
        return objects.filter(obj => obj.date === targetDate);
    }

    function getDateXDaysAgo(inputDate, x) {
        // Parse the input date string using Moment.js
        const parsedDate = moment(inputDate, 'YYYY-MM-DD');
        // Subtract x days from the parsed date
        const pastDate = parsedDate.subtract(x, 'days');
        // Format the past date as YYYY-MM-DD
        const formattedDate = pastDate.format('YYYY-MM-DD');

        return formattedDate;
    }

    const endDateFix = moment(endDate).format('YYYY-MM-DD');
    

    const filteredData = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 9));
    const filteredData2 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 8));
    const filteredData3 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 7));
    const filteredData4 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 6));
    const filteredData5 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 5));
    const filteredData6 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 4));
    const filteredData7 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 3));
    const filteredData8 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 2));
    const filteredData9 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 1));
    const filteredData10 = filterByDate(agg_result, getDateXDaysAgo(endDateFix, 0));

    const day10 = getDateXDaysAgo(endDateFix, 9);
    const day9 = getDateXDaysAgo(endDateFix, 8);
    const day8 = getDateXDaysAgo(endDateFix, 7);
    const day7 = getDateXDaysAgo(endDateFix, 6);
    const day6 = getDateXDaysAgo(endDateFix, 5);
    const day5 = getDateXDaysAgo(endDateFix, 4);
    const day4 = getDateXDaysAgo(endDateFix, 3);
    const day3 = getDateXDaysAgo(endDateFix, 2);
    const day2 = getDateXDaysAgo(endDateFix, 1);
    const day1 = getDateXDaysAgo(endDateFix, 0);

    function leftJoinMultipleDatasets(datasets, key, metric) {
        // Initialize an empty array to store the combined data
        const combinedData = [];
        console.log(metric)
    
        // Build a map of campaign names to objects from each dataset
        const datasetMaps = datasets.map(dataset =>
            dataset.reduce((map, obj) => {
                map[obj[key]] = obj;
                return map;
            }, {})
        );
    
        // Iterate over each object in the first dataset
        datasets[0].forEach(obj => {
            // Get the campaign name for the current object
            const campaignName = obj[key];
    
            // Create an object to store the combined data
            const combinedObj = { campaign_name: campaignName };
    
            // Iterate over each dataset and merge the metrics
            datasets.forEach((dataset, index) => {
                const datasetObj = datasetMaps[index][campaignName];
                if (datasetObj) {
                    combinedObj[`${datasetObj.date}`] = datasetObj[metric]
                    combinedObj.spend = datasetMaps[0][campaignName]['spend']
                } else {
                    combinedObj[`${obj.date}`] = 0;
                }
            });
    
            // Push the combined object to the combined data array
            combinedData.push(combinedObj);
        });
    
        return combinedData;
    }

    function aggDataset (dataset, dateObj) {

    var aggregatedResultTotal = []

    dataset.reduce(function (res, value) {

        const regs = 'regs_ft_lifetime'
        const ftds = `ftds_${model}`
        const react = `react_${model}`
        const ngr = `ngr_${model}`
        const impressions = 'impressions'
        const clicks = 'clicks'
        const spend = 'spend'
        const xAxis = value[dateObj]

        if (!res[xAxis]) {
            res[xAxis] = {
                [dateObj]: xAxis,
                impressions: 0,
                clicks: 0,
                spend: 0,
                regs: 0,
                ftds: 0,
                react: 0,
                ngr: 0
            };
            aggregatedResultTotal.push(res[xAxis]);
        }
        res[xAxis].impressions += parseInt(value[impressions]) || 0;
        res[xAxis].clicks += parseInt(value[clicks]) || 0;
        res[xAxis].spend += parseInt(value[spend]) || 0;
        res[xAxis].regs += parseInt(value[regs]) || 0;
        res[xAxis].ftds += parseInt(value[ftds]) || 0;
        res[xAxis].react += parseInt(value[react]) || 0;
        res[xAxis].ngr += parseInt(value[ngr]) || 0;

        return res;
    }, {});

    aggregatedResultTotal = aggregatedResultTotal.map((obj) => {
        //const impressions = obj.impressions
        const date = moment.tz(obj.date, 'Europe/Paris').format('YYYY-MM-DD')
        const ftdsBase = obj.ftds
        const reactBase = obj.react
        const ngrBase = obj.ngr
        const objDimension = dimension
        const objValue = obj[dimension]
        const clicks = obj.clicks || 0
        const spend = obj.spend || 0;
        //const regs = obj.regs
        //const ftds = obj.ftds;
        const ngr = ngrBase || 0
        //const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftdsBase === 0 || isNaN(ftdsBase) ? 0 : spend / ftdsBase;
        //const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        const totalConv = reactBase + ftdsBase || 0
        const netProfit = ngr - spend || 0
        const recovered = (ngr / spend) || 0
        const costConv = spend === 0 || totalConv === 0 || isNaN(totalConv) ? 0 : spend / totalConv;

        return {
            
            [objDimension]: 'Total',
            date: date,
            ngr: parseFloat((ngr).toFixed(2)),
            clicks: clicks,
            totalConv: totalConv,
            costConv: parseFloat((costConv).toFixed(2)),
            cpa: parseFloat((cpa).toFixed(2)),
            cpc: parseFloat((cpc).toFixed(2)),
            spend: parseFloat((spend)),
            netProfit: parseFloat((netProfit).toFixed(2)),
            recovered: parseFloat((recovered).toFixed(4)),
        };
    });

    return aggregatedResultTotal

    }

    const totals = aggDataset (dataset3, 'date')

    function sortByDateAscending(dataset) {
        // Clone the dataset to avoid modifying the original array
        const sortedDataset = [...dataset];
    
        // Sort the cloned dataset by the "date" key in ascending order
        sortedDataset.sort((a, b) => new Date(b.date) - new Date(a.date));
    
        return sortedDataset;
    }

    const sortedTotals = sortByDateAscending(totals)
    
    // Example usage:
    const datasets = [aggregatedResult, filteredData, filteredData2, filteredData3, filteredData4, filteredData5, filteredData6, filteredData7, filteredData8, filteredData9, filteredData10]
    const key = dimension;
    const joinMetric = metric; // Change this to the desired metric
    
    const joinedData = leftJoinMultipleDatasets(datasets, key, joinMetric);

    var daily_result = [];
    daily_result = joinedData.map((obj) => {
        
        const objDimension = dimension
        const objValue = obj[dimension]
        const spend = obj.spend
        const day10 = getDateXDaysAgo(endDateFix, 9);
        const day9 = getDateXDaysAgo(endDateFix, 8);
        const day8 = getDateXDaysAgo(endDateFix, 7);
        const day7 = getDateXDaysAgo(endDateFix, 6);
        const day6 = getDateXDaysAgo(endDateFix, 5);
        const day5 = getDateXDaysAgo(endDateFix, 4);
        const day4 = getDateXDaysAgo(endDateFix, 3);
        const day3 = getDateXDaysAgo(endDateFix, 2);
        const day2 = getDateXDaysAgo(endDateFix, 1);
        const day1 = getDateXDaysAgo(endDateFix, 0);

        const day10Value = obj[day10];
        const day9Value = obj[day9];
        const day8Value = obj[day8];
        const day7Value = obj[day7];
        const day6Value = obj[day6];
        const day5Value = obj[day5];
        const day4Value = obj[day4];
        const day3Value = obj[day3];
        const day2Value = obj[day2];
        const day1Value = obj[day1];

        return {
            
            [objDimension]: objValue,
            spend: spend,
            [day10] : day10Value || 0,
            [day9] : day9Value || 0,
            [day8] : day8Value || 0,
            [day7] : day7Value || 0,
            [day6] : day6Value || 0,
            [day5] : day5Value || 0,
            [day4] : day4Value || 0,
            [day3] : day3Value || 0,
            [day2] : day2Value || 0,
            [day1] : day1Value || 0,
        };
    });

    return {daily_result, day10,
        day9,
        day8,
        day7,
        day6,
        day5,
        day4,
        day3,
        day2,
        day1,
        totals: sortedTotals}

}