export default function ParseCumulative(data, startMonth, endMonth) {
        // Helper to check if a date falls within the given range (inclusive)
        const calculateCumulativeProgression = (data, month) => {
            const filteredData = data.filter(item => item.date.startsWith(month));
            let cumuClicks = 0, cumuSpend = 0, cumuFtds = 0, cumuNGR = 0;

    
            return filteredData.map(item => {
                cumuClicks += item.clicks;
                cumuSpend += item.spend;
                cumuFtds += item.ftds;
                cumuNGR += item.ngr
                const dayOfMonth = item.date.split('-')[2];
                return {
                    date: item.calendar_date,
                    day_of_month: dayOfMonth,
                    clicks: cumuClicks,
                    spend: cumuSpend,
                    ftds: cumuFtds,
                    ngr : cumuNGR,
                    cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0
                };
            });
        };
    
        // Determine last month's "YYYY-MM" string
        const lastMonthDate = new Date(`${endMonth}-01`);
        const lastMonthString = lastMonthDate.toISOString().substring(0, 7);
    
        // Calculate cumulative progression for "last month" and "this month"
        const lastMonthProgression = calculateCumulativeProgression(data, lastMonthString);
        const thisMonthProgression = calculateCumulativeProgression(data, startMonth);
    
        return { lastMonth: lastMonthProgression, thisMonth: thisMonthProgression };

}