import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function OptionsGroupingPreviewDataGrid(props) {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const currentMetrics = props.currentMetrics
    const dataProp = props.dataProp
    const theme = props.theme

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else { responseText = windowHeight * 0.017 }

    let responseRow;

    if (windowWidth < 1281) {
        responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
        responseRow = windowHeight * 0.05
    } else { responseRow = windowHeight * 0.04 }


    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        clicks: false,
        impressions: false,
        regs: false,
        channel: false,
        cpr: false,
        campaign: true,
        updated_at: false,
        spend: false,
        ftds: false,
        ftd_ngr: false
    });



    const valueFormat = (value, field) => {
        if (field === 'cpa' || field === 'spend' || field === 'cpr' || field === 'ftd_ngr') {
            return `€${value}`;
        } else if (field === 'spend_rate' || field === 'roas') {
            const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
            return `${percent}%`;
        }
        return value;
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
        record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labels = Object.keys(labelObject).map((key, index) => {

        let newLabel;
        if (key === 'ftds') {
            newLabel = currentMetrics.primaryMetric
        } else if (key === 'ftd') {
            newLabel = currentMetrics.primaryMetric
        } else if (key === 'regs') {
            newLabel = currentMetrics.secondaryMetric
        } else if (key === 'ftd_ngr') {
            newLabel = currentMetrics.primaryRevenue
        } else if (key === 'kw_type') {
            newLabel = currentMetrics.primaryDimensionString.toLowerCase()
        } else { newLabel = key }

        const textFormatter = (field) => {

            if (field !== 'impressions' || field !== 'clicks' || field !== 'spend' || field !== 'regs' || field !== 'ftds' || field !== 'ngr') {
                return theme.color
            } else {
                return 'white'
            }

        }

        return {
            field: key,
            headerName: newLabel,
            //minWidth: windowWidth * columnWidth * 1.25, 
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'table-header',
            align: 'center',
            type: 'number',
            renderCell: (params) => {
                const { value, field } = params;
                const heatmapColor = calculateHeatmapColor(value, { field });
                if (index < 1) {
                    return (
                        <div
                            className="heatmap-cell"
                            style={{
                                backgroundColor: heatmapColor,
                                padding: '7%',
                                color: theme.color,
                                width: '100%',
                                fontSize: responseText * 0.8
                            }}
                        >
                            {valueFormat(params.formattedValue, params.field)}
                        </div>
                    );
                } else if (params.aggregation) {
                    return (
                        <div
                            className="heatmap-cell-aggregated"
                            style={{
                                fontWeight: 'bold', // Add your custom font weight
                                display: 'flex', // Apply flex styles to center content vertically/horizontally
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%', // Ensure the cell takes up the full height
                                width: '100%',
                                color: theme.color,
                                paddingLeft: 0,
                                fontFamily: 'Electrolize',
                                fontSize: responseText * 0.8,
                            }}
                        >
                            {valueFormat(params.formattedValue, params.field)}
                        </div>
                    );
                } else return (
                    <div
                        className="heatmap-cell"
                        style={{
                            backgroundColor: heatmapColor,
                            padding: '10%',
                            width: '100%',
                            fontSize: responseText * 0.8,
                            color: textFormatter(params.field)

                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            },

        };
    });

    const columnRanges = {};
    Object.keys(labelObject).forEach((column) => {
        const values = dataProp.map((record) => record[column]);
        const min = Math.min(...values);
        const max = Math.max(...values);
        columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
        const { min, max } = columnRanges[column.field];
        const range = max + 1 - min;

        // Define input colors for the heatmap in RGB format
        const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
        const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

        const relativeValue = (value - min) / range;
        const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
        const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
        const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

        return `rgba(${red},${green},${blue},0.9)`;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ color: theme.color }}>
                <GridToolbarColumnsButton sx={{ color: theme.color }} />
                <GridToolbarFilterButton sx={{ color: theme.color }} />
                <GridToolbarDensitySelector sx={{ color: theme.color }} />
                <GridToolbarExport sx={{ color: theme.color }} />
            </GridToolbarContainer>
        );
    }

    function calculateCPA(spend, ftd) {
        const cpa = (spend / ftd)

        return Math.round(cpa)
    }

    function calculateCPR(spend, regs) {
        const cpr = (spend / regs)

        return Math.round(cpr)
    }

    function calculateROAS(spend, ngr) {
        const roas = (parseFloat(ngr) / parseFloat(spend))

        return roas
    }

    function calculateTotal(spend, ftd) {
        return "TOTAL"
    }


    const parseTotal = {
        label: '',
        getCellValue: ({ row }) => ({ ftd_ngr: row.ftd_ngr }),
        apply: ({ values }) => {
            let ftd_ngr = 0;
            values.forEach((value) => {
                if (value) {
                    ftd_ngr += parseFloat(value.ftd_ngr) || 0; // Adds 0 if value.spend is null or NaN
                }
            });
            return ftd_ngr;
        },
        columnTypes: ['number'],
    };

    const cpa = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
        apply: ({ values }) => {
            let spend = 0;
            let ftd = 0;
            values.forEach((value) => {
                if (value) {
                    spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                    ftd += value.ftd || 0;
                }
            });
            return calculateCPA(spend, ftd);
        },
        columnTypes: ['number'],
    };

    const cpr = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, regs: row.regs }),
        apply: ({ values }) => {
            let spend = 0;
            let regs = 0;
            values.forEach((value) => {
                if (value) {
                    spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                    regs += value.regs || 0;
                }
            });
            return calculateCPR(spend, regs);
        },
        columnTypes: ['number'],
    };

    const roas = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, ngr: row.ftd_ngr }),

        apply: ({ values }) => {
            let spend = 0;
            let ngr = 0;
            values.forEach((value) => {
                if (value) {
                    spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                    ngr += parseFloat(value.ngr) || 0;
                }
            });
            return calculateROAS(spend, ngr);
        },
        columnTypes: ['number'],
    };

    const total = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
        apply: ({ values }) => {
            let spend = 0;
            let ftd = 0;
            values.forEach((value) => {
                if (value) {
                    spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                    ftd += value.ftd || 0;
                }
            });
            return calculateTotal(spend, ftd);
        },
        columnTypes: ['number'],
    };

    const largeRows = ((windowHeight / 60).toFixed(0))

    return (
        <Box
            style={{
                width: '100%',
                height: windowHeight * 0.82,
                fontSize: 50,
                color: 'black',
                fontFamily: 'Electrolize',
                marginTop: '1%'

            }}
            sx={{
                '& .table-header': {
                    headerAlign: 'center',
                    align: 'center',
                    fontFamily: 'Electrolize',
                    color: theme.color,
                    fontSize: responseText,
                },
                '& .MuiDataGrid-row': { backgroundColor: theme.backgroundColor },
                '& .MuiTablePagination-root': { color: theme.color },
                width: '100%'
            }}
        >
            <DataGridPremium
                rows={dataProp}
                columns={labels}
                rowHeight={responseRow}
                slots={{ toolbar: CustomToolbar }}
                pagination
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: parseInt(largeRows) }
                    },
                    sorting: {
                        sortModel: [{ field: 'spend', sort: 'desc' }],
                    },
                }}

            />
        </Box>
    );
}