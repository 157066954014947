import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import AttributionPanel from '../Attribution/AttributionPanel';
import moment from 'moment-timezone';

const windowHeight = Dimensions.get('window').height;

const AttributionComparison = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientUpper = configArray.clientID
  const clientBase = clientUpper.toLowerCase();
  let client;
  if (clientBase === 'all_clients') {
    client = 'stack'
  } else { client = clientBase }
  const cubeTable = client + "_attribution_export"
  const theme = props.theme
  const homeTitle = "Attribution Comparison";
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const chartType = "comparison"
  const role = configArray.role

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  // Get the end date (e.g., the end of the current month)
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  // Convert the start and end dates to strings in YYYY-MM-DD format
  const startDateString = previousDate.toISOString().slice(0, 10);
  const endDateString = endDate.toISOString().slice(0, 10);

  const filter1name = 'brand'
  const filter2name = 'channel'

  const [filter1List, setFilter1List] = useState('Total')
  const [filter2List, setFilter2List] = useState('Total')

  const filterValueBase = {
    filter1List : [],
    filter2List : [],
    filter3List : []
  }
const [filterList, setFilterList] = useState(filterValueBase);

  const filterParams = {
    filter1name,
    filter2name,
    filter1List,
    filter2List,
    setFilter1List,
    setFilter2List,
  }

  const filterBase = {
    start: "2023-01-01",
    end: yesterdayProp,
    [filter1name]: "Total",
    [filter2name]: 'Total',
  };

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }
  const [filters, setFilters] = useState(filterBase);

  const start = filters.start
  const end = filters.end
  let filter1Filter, filter1Operator;

  if (filters[filter1name] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name].length === 0) {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name][0] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else {
    filter1Filter = filter1List
    filter1Operator = ['equals']
  }

  let filter2Filter, filter2Operator;

  if (filters[filter2name] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name].length === 0) {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name][0] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else {
    filter2Filter = filter2List
    filter2Operator = ['equals']
  }

  const propObjectBase = {
    data: [],
    start: "2023-01-01",
    end: yesterdayProp,
    theme: theme,
    [filter1name]: "Total",
    [filter2name]: 'Total',
  };

  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {

    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      [filter1name]: prop[filter1name],
      [filter2name]: prop[filter2name],
    };

    setFilters(filterUpdate)

  };

  const lastUpdated = 'loading...'

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientUpper}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientUpper}
      />
      <AttributionPanel
        theme={theme}
        windowHeight={windowHeight}
        propObject={propObject}
        handleStateChange={handleStateChange}
        filters={filters}
        chartType={chartType}
        cubeTable={cubeTable}
        filterParams={filterParams}
        setFilterList={setFilterList}
        filterList={filterList}
        currentMetrics={currentMetrics}
        configArray={configArray}
      >
      </AttributionPanel>

    </View>

  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default AttributionComparison