import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import LoadingComponent from '../Components/Loader.js';
import ExecSummaryContent from './ExecSummaryContent.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const ExecSummaryPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [panelContent, setPanelContent] = useState('metrics')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
    const cubeTable = 'glitnor_execSummary_metrics'
    const dynamicFilter = 'channel'
   

    const filter1name = 'brand'
    const filter2name = 'country'
    const filter3name = 'brand'
    const filter4name = 'country'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')
    const [filter4List, setFilter4List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : [],
        filter4List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);


    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter4name,
        filter1List,
        filter2List,
        filter3List,
        filter4List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
        setFilter4List
    }

    const modelsBase = {
        model1: 'lt_lifetime',
        model2: 'ft_1',
        model3: 'pos_28'
    }

    const [models, setModels] = useState(modelsBase)

    function modelHandler (prop) {

        setModels(prop)
    }

    const dimensions = ['channel']

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
        [filter4name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end

    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    let filter4Filter, filter4Operator;

    if (filters[filter4name] === 'Total') {
        filter4Filter = ['null']
        filter4Operator = ['notEquals']
    } else if (filters[filter4name].length === 0) {
        filter4Filter = ['null']
        filter4Operator = ['notEquals']
    } else if (filters[filter4name][0] === 'Total') {
        filter4Filter = ['null']
        filter4Operator = ['notEquals']
    }  else {
        filter4Filter = filter4List
        filter4Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
        [filter4name]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
            [filter4name]: prop[filter4name],
        };


        setFilters(filterUpdate)

    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeDims = ['dim','type', 'brand', 'country']

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "minus2_day",
            "minus3_day",
            "mtd",
            "prevMonth",
            "prevMonthYear",
            "prevTYD",
            "prevWeek",
            "yesterday",
            "ytd"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": []
        }
    }


    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };


    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
      }, [configArray, filters])

      if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

    const cubeResponseBase = configResponse.data

    if (cubeResponseBase.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }
    
    

    const cubeResponse = cubeResponseBase.map ((obj) => {
        const prevYtd = obj.prevTYD

        return {
            ...obj,
            prevYTD: prevYtd
        }
    })

    const dataProp = {
        aggResult: cubeResponse,
        models
    }


    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <ExecSummaryContent
                        theme={theme}
                        dataProp={dataProp}
                        handleStateChange={handleStateChange}
                        dynamicFilter={dynamicFilter}
                        propObject={propObject}
                        modelHandler={modelHandler}
                        attModalOpen={attModalOpen}
                        setOpen={setOpen}
                        filterParams={filterParams}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                        panelContent={panelContent}
                        setPanelContent={setPanelContent}
                    />

            </View>
        </View>

    )

}

export default ExecSummaryPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})