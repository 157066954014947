import React, { useState , useEffect } from 'react';
import { View , 
    StyleSheet , 
    Dimensions , 
    Text,
    Pressable
} from 'react-native'
import FiltersEnhancedPaths from '../../ComponentsEnhanced/FiltersEnhancedPaths';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AnalyticsPathsRegStartPanels = (props) => {

    const theme = props.theme
    const dataProp = props.dataProp
    const filterLabels = props.filterLabels
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filterParams = props.filterParams
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const currentMetrics = props.currentMetrics
    const primaryMetric = currentMetrics.primaryMetricString
    const primaryRevenue = currentMetrics.primaryRevenueString

    const [textInput, setTextInput] = useState("")
    let jsonObject = dataProp

    function filterJsonObject(jsonObject, textInput) {
        if (textInput === '') {
            return jsonObject;
        }
        return jsonObject.filter(item => {
            // Check if any property values contain the text input
            return Object.values(item).some(value => {
                if (Array.isArray(value)) {
                    return value.some(subValue => subValue.toLowerCase().includes(textInput.toLowerCase()));
                }
                return typeof value === 'string' && value.toLowerCase().includes(textInput.toLowerCase());
            });
        });
    }

    const outputList = filterJsonObject(dataProp, textInput)

    const channelColour = (item) => {
        if (item === 'Organic') {
            return '#4a0080'
        } else if (item === 'Direct') {
            return '#800077'
        } else if (item === 'Display') {
            return '#a80069'
        } else if (item === 'Affiliate') {
            return '#c80058'
        } else if (item === 'Exoclick') {
            return '#e46832'
        } else if (item.includes("EXO")) {
            return '#e46832'
        } else if (item === 'PPC') {
            return '#e46832'
        } else if (item === 'Meta Ads') {
            return '#d8b524'
        } else if (item === 'Paid Social') {
            return '#d8b524'
        } else if (item === 'Google Ads') {
            return '#e46832'
        } else if (item.includes("ADFORM")) {
            return '#a80069'
        } else if (item.includes("_") || item.includes("-")) {
            return '#ba0054'
        } else {
            return 'grey'
        }
    }

    const cellStyle = (item) => {
        let output;
        if (item.length >= 3) {
            output = {
                width: '60%',
                height: windowWidth * 0.07,
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Electrolize',
                textAlign: 'center',
                borderColor: theme.color,
                borderWidth: 1,
                flexDirection: 'row',
                minHeight: '100%',
                maxHeight: '100%',
                flexWrap: 'wrap',
                padding: '10px'
            }
        } else {
            output = {
                width: '60%',
                height: windowHeight * 0.05,
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Electrolize',
                textAlign: 'center',
                borderColor: theme.color,
                borderWidth: 1,
                flexDirection: 'row',
                minHeight: '100%',
                maxHeight: '100%',
                overflow: 'auto',
            }
        }

        return output
    }

    const metricCellStyle = (item) => {
        let output;
        if (item.length >= 3) {
            output = {
                width: '20%',
                height: windowWidth * 0.07,
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Electrolize',
                textAlign: 'center',
                borderColor: theme.color,
                borderWidth: 1,
                //minHeight: windowHeight * 0.05,
                //maxHeight: windowHeight * 0.05,
                //padding: "10px"
            }
        } else {
            output = {
                width: '20%',
                height: windowHeight * 0.05,
                alignItems: 'center',
                justifyContent: 'center',
                fontFamily: 'Electrolize',
                textAlign: 'center',
                borderColor: theme.color,
                borderWidth: 1,
                minHeight: windowHeight * 0.05,
                maxHeight: windowHeight * 0.05,
            }
        }

        return output
    }

    const pathStyle = {
        width: '60%',
        height: windowHeight * 0.05,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Electrolize',
        textAlign: 'center',
        borderColor: theme.color,
        borderWidth: 1,
        flexDirection: 'row',
        minHeight: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        backgroundColor: theme.backgroundColor2,
    }

    const metricStyle = {
        width: '20%',
        height: windowHeight * 0.05,
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Electrolize',
        textAlign: 'center',
        borderColor: theme.color,
        borderWidth: 1,
        minHeight: windowHeight * 0.05,
        maxHeight: windowHeight * 0.05,
        backgroundColor: theme.backgroundColor2,
    }


    const childStyle = {
        padding: '1px',
        borderWidth: 1,
        borderColor: theme.color,
        height: windowHeight * 0.03,
        marginRight: '1%',
        minHeight: windowHeight * 0.03,
        maxHeight: windowHeight * 0.03,
        borderRadius: 5,
        
        
    }

    const childtext = {
        color: 'white',
        fontFamily: 'Electrolize',
        fontSize: windowWidth * 0.01
    }

    const valuetext = {
        color: theme.color,
        fontFamily: 'Electrolize',
        fontSize: windowWidth * 0.01,
    }

    const titleText = {
        backgroundColor: theme.backgroundColor2,
        color: 'white',
        fontSize: windowWidth * 0.012,
        fontWeight: 600,
        fontFamily: 'Electrolize',
    }

    const DisplayComponent = ({ data }) => {
        return (
            <View style={{
                justifyContent: 'center',
                alignItems:'center',
                width: '100%',
                paddingTop: '1%'
                //flex: 1
            }}>
                <View style={{
                            width: '100%',
                            //height: '5%',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems:'center',
                            //backgroundColor: theme.backgroundColor2,
                        }}>
                <View style={pathStyle}>
                    <Text style={titleText}>Path</Text>
                </View>
                <View style={metricStyle}>
                    <Text style={titleText}>RegStarts</Text>
                </View>

                </View>
                {data.map((item, index) => (
                    <View 
                        key={index}
                        style={{
                            width: '100%',
                            //height: '5%',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems:'center',

                        }}
                        >
                            <View style={cellStyle(item.dim)}>
                                {item.dim.flat().map((dimValue, dimIndex) => (
                                    <View key={dimIndex} style={[childStyle, {backgroundColor: channelColour(dimValue)} ]}>
                                        <Text style={childtext}>{dimValue}</Text>
                                    </View>
                                ))}
                            </View>
                            <View style={metricCellStyle(item.dim)}>
                                <Text style={valuetext}>{item.ftds}</Text>
                            </View>


                    </View>
                ))}
            </View>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.filterContainer}>
            <FiltersEnhancedPaths
                style={{
                    height: '10%',
                    borderColor: 'red',
                    borderWidth: 0,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueFilter1={filterLabels}
                handleStateChange={handleStateChange}
                propObject={propObject}
                filterParams={filterParams}
                theme={theme}
                setFilterList={setFilterList}
                filterList={filterList}
                textInput={textInput}
                setTextInput={setTextInput}
                large={1}
                singleModel={true}
            />

            </View>
            <View style={styles.bodyContainer}>
                <DisplayComponent
                data={outputList}
                />
                
            </View>
        </View>
    )

}

export default AnalyticsPathsRegStartPanels

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : '100%',
      minHeight: windowHeight * 0.93,
      width : '100%',
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      padding: '0%'
    },
    filterContainer: {
        height: '10%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
    },
    bodyContainer: {
        height: '90%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection : 'column',
        paddingLeft: '2%',
        paddingRight: '2%',
    }
})