import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';

const windowWidth = Dimensions.get('window').width;


export default function OptionsGroupingAddModal(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const additem = props.addItem
    const child = props.child
    const childIndex = props.childIndex
    const [textValue, setTextValue] = useState("")

    let disabledState = textValue === "";

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const saveHandler = (newDim) => {

        additem(child, newDim)
        
        setOpen(false)

    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Add Item</DialogTitle>
                    <DialogContent sx={{
                        //border: '2px blue solid',
                        //width: windowWidth * 0.9
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <Box component="form" sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                            }}>
                                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, width: '100%', fontFamily: 'Electrolize', }}>
                                    <InputLabel>New Dimension Name</InputLabel>
                                    <OutlinedInput
                                        label="New Dimension Name"
                                        id="outlined-search"
                                        onChange={e => setTextValue(e.target.value)}
                                        type="search"
                                        value={textValue}

                                        sx={{
                                            width: '100%',
                                            left: 0,
                                            fontFamily: 'Electrolize',
                                            color: theme.color,
                                            backgroundColor: theme.backgroundColor,
                                            textAlign: 'center'

                                        }}

                                    />
                                </FormControl>

                            </Box>
                            <View style={{ width: '100%', alignItems: 'end', justifyContent: 'center', padding: '2px' }}>

                                <Button variant="contained"
                                    sx={{
                                        backgroundColor: theme.backgroundColor2,
                                        color: 'white',
                                        fontFamily: 'Electrolize',
                                        fontWeight: 600
                                    }}
                                    onClick={() => saveHandler(textValue)}
                                    disabled={disabledState}

                                >
                                    SAVE
                                </Button>
                                


                            </View>


                        </Box>

                    </DialogContent>
                </View>

            </Dialog>
        </div>

    );
}