export default function OptionsGroupingPreviewCubeCase(groupingData, cubeTable, clientId, auth0_user_id) {


    
    // Initialize cubeDimensions with original items as strings
    const cubeDimensions = [{
        channel : {sql : `channel`, type: `string`},
        brand: {sql : `brand`, type: `string`},
        campaign : {sql : `campaign`, type: 'string'},
        adgroup : {sql : `adgroup`, type: `string`},
        timestamp : {sql : `timestamp`, type: `time`},
        updated_at : {sql : `current_timestamp()`, type: `time`},
}];

    // Iterate through each custom dimension in customDimensions
    groupingData.data.custom_groupings.custom_dimensions.forEach(customDimension => {
        const dimensionNameObject = Object.keys(customDimension);
        const dimensionName = dimensionNameObject[0];
        const dimensionValues = Object.values(customDimension);
        const conditionValuesParent = dimensionValues[0]; // Array of condition objects

        // Build the conditionList for the current dimension as an object with sql statements
        const conditionList = conditionValuesParent.reduce((acc, condition) => {
            const conditionValue = condition.value;
            const conditionOperator = condition.operator;
            const conditionReturnValue = condition.return_value;
            const conditionDimension = condition.dimension;

            // Determine SQL operator based on condition operator
            const operatorSQL = conditionOperator === 'equals' ? '=' : 'LIKE';

            // Add % wildcards for LIKE and construct the sql
            acc.push({
                sql: `${conditionDimension} ${operatorSQL} '${operatorSQL === 'LIKE' ? '%' + conditionValue + '%' : conditionValue}'`,
                label: conditionReturnValue
            });
            return acc;
        }, []);

        // Construct the queryParent structure for the current dimension
        const queryParent = {
            [dimensionName]: {
                type: 'string',
                case: {
                    when: conditionList,
                    else: { label: {sql: `${conditionValuesParent[0].else}`} }
                }
            }
        };

        // Append structured queryParent to cubeDimensions as per the required format
        Object.assign(cubeDimensions[0], queryParent);
    });

    

    // Final query structure with the new format applied to the dynamic dimensions
    const cubeQuery = {
        name: `model_${clientId}_${auth0_user_id}`,
        sql_table: `stack_cubejs.${cubeTable}`,
        dimensions: cubeDimensions[0],
        measures: {
            count: {type: 'count'},
            impressions: {sql : 'impressions', type: 'sum'},
            clicks: {sql : 'clicks', type: 'sum'},
            regs: {sql : 'regs', type: 'sum'},
            ftds: {sql : 'ftds', type: 'sum'},
            spend: {sql : 'spend', type: 'sum'},
            ftd_ngr: {sql : 'ftd_ngr', type: 'sum'},
            retention_ngr: {sql : 'retention_ngr', type: 'sum'},
            deposits: {sql : 'deposits', type: 'sum'},
            deposit_amount: {sql : 'deposit_amount', type: 'sum'},
            reactivations: {sql : 'reactivations', type: 'sum'},
        }
    };

    return cubeQuery;

}
