import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import OptionsGroupingPreviewCase from './OptionsGroupingPreviewCase';
import OptionsGroupingPreviewDataGrid from './OptionsGroupingPreviewDataGrid';
import OptionsGroupingPreviewCubeCase from './OptionsGroupingPreviewCubeCase';

const OptionsGroupingPreview = (props) => {

    const theme = props.theme
    const groupingData = props.groupingData
    const currentMetrics = props.currentMetrics
    const updateArray = props.updateArray

    const newData = OptionsGroupingPreviewCase(updateArray, groupingData, 'adgroup')
    
    return (
        <View style={styles.container}>
            <OptionsGroupingPreviewDataGrid
                theme={theme}
                currentMetrics={currentMetrics}
                dataProp={newData}
                />
            

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '1%',
        borderWidth: 0,
        borderColor: 'yellow',

    }
})

export default OptionsGroupingPreview