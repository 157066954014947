import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import ReactivationsPanel from './ReactivationsPanel';

const ReactivationsCampaign = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const theme = props.theme
  const homeTitle = "React - Campaign";
  const role = configArray.role;
  const channelDim = 'Total'
  const reportDim = "campaign"
  const [lastUpdated, setLastUpdated] = useState("loading...")
  const filterValueBase = {
    filter1List : [],
    filter2List : [],
    filter3List : []
  }
  //const filterValueBase = []
  const [filterList, setFilterList] = useState(filterValueBase);


  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <ReactivationsPanel
        style={styles.panel}
        theme={theme}
        clientID={clientID}
        setFilterList={setFilterList}
        filterList={filterList}
        currentMetrics={currentMetrics}
        setLastUpdated={setLastUpdated}
        channelDim={channelDim}
        reportDim={reportDim}
        configArray={configArray}
      />
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default ReactivationsCampaign