import { Dimensions } from 'react-native'

const windowWidth = Dimensions.get('window').width;

export default function ParseBudget(cubeResponse, cubeTable, type) {

    var agg_result = [];

    if (type === 'table') {       

        cubeResponse.reduce(function (res, value) {

            let kw_type;

            if (windowWidth < 1800 & (value.kw_type).length > 20) {
                kw_type = (value.kw_type).slice(0,50)+"..."
            } else if (windowWidth > 1800 & (value.kw_type).length > 20) {
                kw_type = (value.kw_type).slice(0,50)+"..."
            } else (kw_type = (value.kw_type))

            if (!res[value.kw_type]) {
                res[value.kw_type] = {
                    kw_type: kw_type,
                    channel: value.channel,
                    actual_spend: 0,
                    budget: 0,
                    yesterday_spend: 0,
                    ftd: 0,
                    days_in_month: parseInt(value.days_in_month),
                    current_dom: parseInt(value.current_dom),
                    days_remaining: parseInt(value.days_remaining)
                };
                agg_result.push(res[value.kw_type]);
            }
            res[value.kw_type].actual_spend += parseInt(value.actual_spend);
            res[value.kw_type].budget += parseInt(value.budget);
            res[value.kw_type].ftd += parseInt(value.ftd);
            res[value.kw_type].yesterday_spend += parseInt(value.yesterday_spend);
            return res;
        }, {});

        agg_result = agg_result.map((obj) => {
            const actual_spend = obj.actual_spend;
            const ftd = obj.ftd;
            const budget = obj.budget;
            const current_dom = obj.current_dom
            const days_in_month = obj.days_in_month
            const days_remaining = obj.days_remaining
            const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
            const spend_rate = (actual_spend / budget).toFixed(2)
            const totalSpend = ((budget / current_dom) * days_in_month)
            const remainingSpend = (totalSpend - actual_spend)
            const target_daily = parseFloat(remainingSpend / (days_remaining + 1)).toFixed(2) || 0.0
            return {
                ...obj,
                cpa: parseInt(cpa),
                spend_rate: (isFinite(spend_rate) ? spend_rate : 0),
                target_daily: parseFloat(target_daily)
            };
        });
    }

    if (type === 'graph') {

        cubeResponse.reduce(function (res, value) {
            if (!res[value.calendar_date]) {
                res[value.calendar_date] = {
                    calendar_date: value.calendar_date,
                    actual_spend: 0,
                    budget: 0,
                    yesterday_spend: 0,
                    ftd: 0,
                    days_in_month: parseInt(value.days_in_month),
                    current_dom: parseInt(value.current_dom),
                    days_remaining: parseInt(value.days_remaining)
                };
                agg_result.push(res[value.calendar_date]);
            }
            res[value.calendar_date].actual_spend += parseInt(value.actual_spend);
            res[value.calendar_date].budget += parseInt(value.budget);
            res[value.calendar_date].ftd += parseInt(value.ftd);
            res[value.calendar_date].yesterday_spend += parseInt(value.yesterday_spend);
            return res;
        }, {});
    
        agg_result = agg_result.map((obj) => {
            const actual_spend = obj.actual_spend;
            const ftd = obj.ftd;
            const budget = obj.budget;
            const current_dom = obj.current_dom
            const days_in_month = obj.days_in_month
            const days_remaining = obj.days_remaining
            const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
            const totalSpend = ((budget / current_dom) * days_in_month)
            const remainingSpend = (totalSpend - actual_spend)
            const target_daily = parseFloat(remainingSpend / (days_remaining + 1)) || 0.0
            return {
                ...obj,
                cpa: parseInt(cpa),
                target_daily: target_daily
            };
        });

    }

    return agg_result
}