import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../../Components/AppBarRN';
import TemporaryDrawer from "../../Components/Drawer";
import AnalyticsEntryPanel from './AnalyticsEntryPanel';

const AnalyticsContent = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const theme = props.theme
  const homeTitle = "Analytics - Content Category";
  const role = configArray.role
  const [lastUpdated, setLastUpdated] = useState("loading...")
  const reportDim = 'content_category'

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <AnalyticsEntryPanel
        style={styles.panel}
        theme={theme}
        clientID={clientID}
        currentMetrics={currentMetrics}
        setLastUpdated={setLastUpdated}
        reportDim={reportDim}
        configArray={configArray}
      />
    </View>

  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default AnalyticsContent